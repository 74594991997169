import React, { useEffect, useState } from "react";
import Heading from "../../component/Heading";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { axiosInstance } from "../../constant";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import ButtonCompo from "../../component/ButtonCompo";
import { Link } from "react-router-dom";
import PaginationBar from "../../component/PaginationBar";
import { IoMdRefresh } from "react-icons/io";

function STOrderhistory() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [search, setSearch] = useState("");

  // get orders
  async function getOrders() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/payment/get/all/orders/staff/${currentPage}?keywords=${keyword}&search=${search}`
      );
      const rawData = res.data?.data;
      console.log(res.data?.data);
      setOrderData(rawData?.data);
      setTotalPage(rawData?.totalPages);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrders();
  }, [keyword, reload]);

  function onSearch() {
    setReload((prev) => !prev);
  }

  function onReload() {
    setReload((prev) => !prev);
    setKeyword("");
    setSearch("");
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Order History</Heading>
          <div className="mt-[3rem]">
            <RadioGroup
              onChange={(event) => setKeyword(event?.target?.value)}
              value={keyword}
            >
              <div className="flex flex-wrap">
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="All Orders"
                />
                <FormControlLabel
                  value="Confirmed"
                  control={<Radio />}
                  label="Order Confirmed"
                />
                <FormControlLabel
                  value="Prepeared"
                  control={<Radio />}
                  label="Order Prepeared"
                />
                <FormControlLabel
                  value="Dispatched"
                  control={<Radio />}
                  label="Order Dispatched"
                />
                <FormControlLabel
                  value="Out for Delivary"
                  control={<Radio />}
                  label="Out For Delivery"
                />
                <FormControlLabel
                  value="Delivered"
                  control={<Radio />}
                  label="Order Delivered"
                />
                <FormControlLabel
                  value="Canceled"
                  control={<Radio />}
                  label="Order Canceled"
                />
              </div>
            </RadioGroup>
          </div>
          <div className="w-full flex justify-end gap-3 mt-[2rem]">
            <ButtonCompo
              variant={"outline"}
              startIcon={<IoMdRefresh />}
              onClick={onReload}
            >
              Refresh
            </ButtonCompo>
            <section className="flex gap-3">
              <TextField
                size="small"
                placeholder="Search By Phone No"
                onChange={(event) => setSearch(event?.target?.value)}
              />
              <ButtonCompo variant={"primary"} onClick={onSearch}>
                Search
              </ButtonCompo>
            </section>
          </div>
          <div className="mt-5">
            <Table>
              <TableHead>
                <TableRow className="bg-[#FF8000]">
                  <TableCell className="!text-white !font-semibold !text-center">
                    Serial No.
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    Order ID
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    Total Products
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    Bill Amount
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    Date
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    Payment Status
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    Invoice
                  </TableCell>
                  <TableCell className="!text-white !font-semibold !text-center">
                    More
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData?.map((order, i) => (
                  <TableRow className="bg-white">
                    <TableCell className="!text-slate-800 !text-center">
                      {i + 1}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      <span className="bg-orange-500 px-3 py-1 rounded-full text-white font-semibold">
                        {order?.order_id}
                      </span>
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      {order?.products?.length}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      ₹{order?.totalPrice}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      {new Date(order?.dateAndTime).toLocaleString("en-IN") ===
                      "Invalid Date"
                        ? new Date(order?.date).toLocaleDateString("en-IN")
                        : new Date(order?.dateAndTime).toLocaleString("en-IN")}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      {order?.paymentStatus === "success" ? (
                        <span className="text-green-700 px-3 py-1 rounded-full bg-green-200/50 font-medium">
                          Success
                        </span>
                      ) : order?.paymentStatus === "Due" ? (
                        <span className="text-yellow-700 px-3 py-1 rounded-full bg-yellow-200/50 font-medium">
                          Awaiting
                        </span>
                      ) : (
                        <span className="text-red-700 px-3 py-1 rounded-full bg-red-200/50 font-medium">
                          Failed
                        </span>
                      )}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      {order?.invoice === undefined || order?.length === 0 ? (
                        <ButtonCompo
                          disabled
                          className="!bg-[#FF8000] opacity-50 !text-white !px-7 !font-semibold"
                        >
                          Invoice
                        </ButtonCompo>
                      ) : (
                        <Link to={order?.invoice} target="_blank">
                          <ButtonCompo className="!bg-[#FF8000] !text-white !px-7 !font-semibold">
                            Invoice
                          </ButtonCompo>
                        </Link>
                      )}
                    </TableCell>
                    <TableCell className="!text-slate-800 !text-center">
                      <Link to={`/admin/order/get/${order?._id}`}>
                        <ButtonCompo>More</ButtonCompo>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div>
            <PaginationBar
              totalTablePage={totalPage}
              setTablePage={setCurrentPage}
              tablePage={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default STOrderhistory;
