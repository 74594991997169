import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Box,
  Chip,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import SelectField from "../../component/SelectField";
import PaginationBar from "../../component/PaginationBar";

function STManualOrderHistory() {
  // navigate to home if staff is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [orderData, setOrderData] = useState([]);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  // get manual orders
  async function getOrders() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/manual/order/get/all/orders/${page}/30`
      );
      console.log(res.data?.data);
      setTotalPages(res.data?.data?.totalPages);
      setOrderData(res.data?.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function changeStatus(status, orderId) {
    setLoading(true);
    const data = {
      status: status,
      orderId: orderId,
    };
    console.log(data);
    try {
      const res = await axiosInstance.post(
        `/api/v1/manual/order/status/change`,
        data
      );
      setOpen(true);
      setSuccess(true);
      setMessage("Status has been changed");
      setReload((prev) => !prev);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function onReload() {
    setReload((prev) => !prev);
  }

  const orderStatusArray = [
    {
      title: "Waiting",
      value: "Waiting",
    },
    {
      title: "Delivered",
      value: "Delivered",
    },
    {
      title: "Canceled",
      value: "Canceled",
    },
  ];

  useEffect(() => {
    getOrders();
  }, [reload, page]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Manual Orders History</Heading>
          <div className="w-full mt-5">
            {orderData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full flex justify-end gap-3 mt-[2rem]">
                  <ButtonCompo
                    variant={"outline"}
                    startIcon={<IoMdRefresh />}
                    onClick={onReload}
                  >
                    Refresh
                  </ButtonCompo>
                  <ButtonCompo
                    variant={"primary"}
                    href="/admin/manual/orders/new"
                  >
                    new manual orders
                  </ButtonCompo>
                </div>
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Date
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          User
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Phone Number
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Status
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Order List
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {orderData?.map((order, i) => (
                        <TableRow>
                          <TableCell className="!text-center">
                            {i + 1}
                          </TableCell>
                          <TableCell className="!text-center">
                            {new Date(order?.date).toLocaleString("en-IN")}
                          </TableCell>
                          <TableCell className="!text-center !font-semibold">
                            {order?.username}
                          </TableCell>
                          <TableCell className="!text-center">
                            {order?.phone}
                          </TableCell>
                          <TableCell className="!text-center !space-x-2 !flex !justify-center !items-center">
                            <section className="w-[10rem]">
                              <SelectField
                                valueArray={orderStatusArray}
                                value={order?.orderStatus}
                                setValue={(value) =>
                                  changeStatus(value?.target?.value, order?._id)
                                }
                              />
                            </section>
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox
                              trigger={<ButtonCompo>view</ButtonCompo>}
                              title="Order Lists"
                            >
                              <div>
                                {order?.supportingDocs?.length === 0 ? (
                                  <div className="w-full mt-[5rem]">
                                    <NotFound width="10rem" />
                                  </div>
                                ) : (
                                  <div className="grid grid-cols-3">
                                    {order?.orderPath?.map((img, i) => (
                                      <Link
                                        to={`${SERVER_URL}/api/v1/file/get/${img}`}
                                        target="_blank"
                                      >
                                        <div className="w-[5rem] h-[5rem]">
                                          <img
                                            src={`${SERVER_URL}/api/v1/file/get/${img}`}
                                            className="w-full h-full object-contain"
                                            alt=""
                                          />
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </DialogBox>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <PaginationBar setTablePage={setPage} tablePage={page} totalTablePage={totalPages} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* ; */
}

export default STManualOrderHistory;
