import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Box,
  Chip,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function STTransanctions() {
  // navigate to home if staff is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [productData, setProductData] = useState([]);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");

  // get products
  async function getProducts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/wallet/get/all/transanctions`
      );
      setProductData(res.data?.data);
      console.log(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function onSearch() {
    setReload((prev) => !prev);
  }

  function onReload() {
    setSearch("");
    setReload((prev) => !prev);
  }

  useEffect(() => {
    getProducts();
  }, [reload, page]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Transanction History</Heading>
          <div className="w-full mt-5">
            {productData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full flex justify-end gap-3 mt-[2rem]">
                  <ButtonCompo
                    variant={"outline"}
                    startIcon={<IoMdRefresh />}
                    onClick={onReload}
                  >
                    Refresh
                  </ButtonCompo>
                </div>
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          User
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Phone Number
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Amount
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          UPI ID
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Bank Account Number
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          IFSC Code
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Status
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Supporting docs
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {productData?.map((withdrawData, i) => (
                        <TableRow>
                          <TableCell className="!text-center">
                            {i + 1}
                          </TableCell>
                          <TableCell className="!text-center !font-semibold">
                            {withdrawData?.user?.fullName}
                          </TableCell>
                          <TableCell className="!text-center">
                            {withdrawData?.user?.phone}
                          </TableCell>
                          <TableCell className="!text-center !space-x-2">
                            {withdrawData?.amount}
                          </TableCell>
                          <TableCell className="!text-center">
                            {withdrawData?.withdrawalReq?.upiId
                              ? withdrawData?.withdrawalReq?.upiId
                              : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {withdrawData?.withdrawalReq?.bankAcNumber
                              ? trans?.withdrawalReq?.bankAcNumber
                              : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {withdrawData?.withdrawalReq?.ifscCode
                              ? trans?.withdrawalReq?.ifscCode
                              : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {withdrawData?.isDone ? "Completed" : "Incomplete"}
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox
                              trigger={<ButtonCompo>view</ButtonCompo>}
                              title="Supporting docs"
                            >
                              <div>
                                {withdrawData?.supportingDocs?.length === 0 ? (
                                  <div className="w-full mt-[5rem]">
                                    <NotFound width="10rem" />
                                  </div>
                                ) : (
                                  <div className="grid grid-cols-3">
                                    {withdrawData?.supportingDocs?.map(
                                      (img, i) => (
                                        <Link to={`${SERVER_URL}/api/v1/file/get/${img}`} target="_blank">
                                          <div className="w-[5rem] h-[5rem]">
                                            <img
                                              src={`${SERVER_URL}/api/v1/file/get/${img}`}
                                              className="w-full h-full object-contain"
                                              alt=""
                                            />
                                          </div>
                                        </Link>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            </DialogBox>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* ; */
}

export default STTransanctions;
