import React, { useEffect } from "react";
import { useState } from "react";
import logo from "../assets/companyLogo.png";

function Legal() {
  const [page, setPage] = useState("Privacy Policy");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div className="w-full">
      <header className="p-5">
        <img src={logo} className="w-[4rem]" alt="" />
      </header>
      <div className="px-10 py-5">
        <h1 className="text-[30px] font-bold text-center">{page}</h1>
        {page === "Terms Of Service" ? (
          <div className="mt-5">
            <p>
              This document is an electronic record and published in accordance
              with the provisions of the Information Technology Act 2000 and the
              rules thereunder and generated by a computer system and does not
              require any physical or digital signatures.
            </p>
            <br />
            <p>
              A. This website www.Fresly.in{" "}
              <span className="font-semibold">(Website)</span> and mobile
              application ‘Fresly’ <span className="font-semibold">(App)</span>{" "}
              (Website and App collectively referred to as the
              <span className="font-semibold">“Platform”</span>) is operated and
              managed by the Company. The term <b>Company</b>
              shall collectively and individually refer to each company which
              operates and manages the Platform for undertaking retail business
              with respect to the respective cities. You can access the complete
              Company details{" "}
              <a
                href="https://www.zeptonow.com/company-details"
                className="underline"
              >
                here
              </a>
              .
            </p>
            <br />
            <p>
              B. These terms of use (<b>“Terms”</b>) govern Your use of the
              Platform, and for the purpose of these Terms the Company may
              wherever context so require, be also referred to as,{" "}
              <b>“Fresly”</b>, <b>"We"</b>, <b>"Us"</b>, or <b>"Our"</b> and the
              terms <b>“You”</b>, <b>“Your”</b> or <b>“User”</b> refer to user
              of the Platform. We value the trust You place in Us. That’s why,
              We maintain reasonable security standards for securing the
              transactions and Your information.
            </p>
            <br />
            <p>
              C. Please read these Terms carefully before You use the Platform.
              If You do not agree to these Terms, You may not use the Products
              and Services (defined hereinafter) on the Platform, and We request
              You to uninstall the App. By even merely using/accessing the
              Platform, You shall be contracting with Fresly and You signify
              Your acceptance to these Terms and other Fresly policies
              (including but not limited to the cancellation & refund policy of
              the Company and published privacy policy (‘Privacy Policy’)) as
              posted on the Platform and amended from time to time, which takes
              eect on the date on which You use the Platform, and thereby create
              a legally binding arrangement to abide by the same. Further, by
              furnishing Your personal information, You consent to Fresly
              accessing Your personal information for the purpose of supplies,
              You also agree that You are interested in availing the supplies
              through the Platform in accordance with these Terms.
            </p>
            <br />
            <p>
              D. Fresly retains an unconditional right to modify or amend these
              Terms. You can determine when these Terms were last modified by
              referring to the “Last Updated” legend above. You can access the
              latest version of these Terms at any given time on the Platform.
              You should regularly review the Terms on the Platform. Your
              continued use of and access to the Platform shall be Your consent
              to such changes. In the event the modified Terms are not
              acceptable to You, You should discontinue accessing the Platform.
            </p>
            <br />
            <p>1. Products and Services</p>
            <br />
            <p>
              1.1. You acknowledge that the Platform allows You to
              purchase/avail the following Products/Services including
            </p>
            <br />
            <p>
              1.1.1. a personal, limited, non-exclusive, non-transferable, and
              revocable privilege to access and use the Platform for the
              purposes of purchasing select products including consumer goods
              such as grocery, pharmaceutical products, alcohol beverages,
              tobacco products (collectively, <b>“Product(s)”</b>) from the
              Company, sold on a business to consumer (B2C) basis for personal
              consumption and not for resale. The Company does not provide
              services such as handling of Products, last mile delivery services
              including late night delivery and other services (collectively,{" "}
              <b>“Services"</b>) without purchase of Products on Platform and
              the fee may be charged for the same.
            </p>
            <p>
              1.1.2. promptly and efficiently responding to Your queries or
              issues relating to Your Account and the supplies availed by You,
              using the Platform.
            </p>
            <br />
            <p>
              1.2. The said revocable privilege to access does not
              include/permit resale or commercial use of the Platform or its
              Content (as defined below), in any manner. The Company may, at its
              discretion, add, modify, or remove any of the Products and/or
              Services listed above from time to time without notice. The
              Company may also make applicable, at its discretion, additional
              terms and conditions specific to any category or section of
              Products in addition to these Terms and Your purchase of any of
              such category or section of Products shall also be governed by
              such additional terms and conditions. The Company reserves the
              right to provide the supplies in any area or city in India, as may
              be determined by the Company in its sole discretion. Before
              applying or registering for availing any of the supplies, we
              request You to please check if the supplies are available in Your
              area or city. The Company provides last mile delivery of Products
              by itself. by engaging third party pick-up and delivery service
              providers
            </p>
            <br />
            <p>
              1.3. The Company does not: (i) adopt any ‘unfair trade practices’
              either on the Platform or otherwise with respect to the Products
              and Services; (ii) discriminate between Users of the same class or
              make any arbitrary classification of the Users; or (iii)
              discriminate between the third-party delivery service providers.
              2. Eligibility to Use
            </p>
            <br />
            <p>
              2.1. The supplies are not available to minors i.e., persons under
              the age of 18 (eighteen) years, undischarged insolvent, or to any
              Users who are not competent to enter into a contract under the
              Indian Contract Act, 1872, unless otherwise provided hereunder.
              You hereby represent that You are: (i) of legal age to form a
              binding contract, (ii) not a person barred from receiving the
              supplies from the Platform under the applicable laws; and (iii)
              competent to enter into a binding contract. If You are under the
              age of 18, You shall use and access the Platform only with the
              express consent of a parent or guardian and under their
              supervision.
            </p>
            <br />
            <p>
              2.2. You will not discriminate against third-party delivery
              service providers based on race, religion, caste, creed, national
              origin, disability, sexual orientation, sex, marital status,
              gender identity, age or any other metric which is deemed to be
              unlawful under applicable laws. Any credible proof of such
              discrimination, including any refusal to receive supplies based on
              the above metrics, whether alone or in conjunction with any other
              metric, shall render you ineligible, leading to suspension of
              access to the Platform. You will not have any claim towards, and
              we will not have any liability towards any suspension which is
              undertaken as a result of the aforementioned event.
            </p>
            <br />
            <p>3. User Account, Password, and Security</p>
            <br />
            <p>
              3.1. In order to access the supplies on the Platform, You will
              have to register and create an account on the Platform by
              providing details as may be required in the Privacy Policy
              (“Account”).
            </p>
            <br />
            <p>
              3.2. You are solely responsible for the information You provide to
              Us. You shall ensure and confirm that the Account information and
              all information provided by You is complete, accurate and
              up-to-date. If there is any change in the Account information, or
              if any information is found to be incomplete or incorrect, You
              shall promptly update Your Account information on the Platform or
              request Fresly for information revision or update. If the
              information provided by You is untrue, inaccurate, unauthorised,
              not current, or incomplete (or becomes untrue, inaccurate, not
              current, or incomplete), or Fresly reserves the right to refuse
              any and all the supplies, if Fresly has reasonable grounds to
              suspect that such information is untrue, inaccurate, not current,
              or incomplete.. Fresly reserves the express right to refuse access
              to the Platform at any time without notice for Your failure to
              abide by the Terms as set forth herein or to comply with
              applicable laws including with respect to accessing alcoholic
              beverages and/or tobacco products through the Platform.
            </p>
            <br />
            <p>
              3.3. You will be responsible for the confidentiality of the
              Account information provided and fully responsible for all
              activities that take place on Your Account. You agree to
              immediately notify Fresly of any unauthorised access of Your
              Account. You may be held liable to pay damages for losses caused
              due to unauthorised use of Your Account as a result of Your
              failure to keep Your Account information secure and confidential
            </p>
            <br />
            <p>4. Payment related Information</p>
            <br />
            <p>
              4.1. The information relating to the accepted payment methods on
              the Platform shall be displayed during the purchasing process
            </p>
            <br />
            <p>
              4.2. To the extent permitted by applicable law and subject to the
              Privacy Policy, You acknowledge and agree that the Company may use
              certain third-party vendors and service providers, including
              payment gateways, to process payments and manage payment card
              information. The Company can ensure such third-party vendors and
              service providers possess necessary licenses from the appropriate
              authority.
            </p>
            <br />
            <p>
              4.3. In order to avail the supplies, You undertake to use and
              provide valid bank details or other details required for
              facilitating payment towards the supplies (“Payment Details”). By
              providing the Payment Details, You represent, warrant, and
              covenant that: (a) You are legally authorized to provide such
              Payment Details; (b) You are legally authorized to perform
              payments using such Payment Details; and (c) such action does not
              violate the terms and conditions applicable to Your use of such
              Payment Details or applicable law. You may add, delete, and edit
              the Payment Details You have provided from time to time through
              the Platform.
            </p>
            <br />
            <p>
              4.4. Except to the extent otherwise required by applicable law,
              the Company is not liable for any payments authorized through the
              Platform using Your Payment Details. Particularly, the Company is
              not liable for any payments that do not complete because:(a) Your
              bank account or any other payment did not contain sufficient funds
              to complete the transaction; (b) You have not provided the Company
              with correct Payment Details; (c) Your payment card has expired;
              or (d) circumstances beyond the Company’s reasonable control (such
              as, but not limited to, power outages, interruptions of cellular
              service, or any other interferences from an outside force) which
              prevent the execution of the transaction.
            </p>
            <br />
            <p>
              4.5. The Company shall not be responsible for any unauthorised
              transactions conducted on the Platform using Your Payment Details.
              The Company shall not be obligated to refund any money to You in
              such instances.
            </p>
            <br />
            <p>
              4.6. The payment facility provided by Company is neither a banking
              nor a financial service but is merely a facility providing an
              electronic, automated online electronic payment system, and
              receiving payment on delivery for the transactions on the Platform
              using the existing authorized banking infrastructure and card
              payment gateway networks, as may be applicable. For some payment
              methods, Your issuer may charge You certain fees, such as
              transaction fees or other fees, relating to the processing of Your
              transaction
            </p>
            <br />
            <p>
              4.7. <b>Fresly Wallet</b>: The Company provides a Wallet facility
              as one of the Payment options to its Users (“<b>Fresly Wallet</b>
              ”) which can be used for availing supplies on the Platform. You
              hereby consent that Your use of Fresly Wallet is subject to the
              geographical and other usage restrictions as may be made
              applicable from time to time and shall also be subject to the
              additional terms and conditions.
            </p>
            <br />
            <p>
              4.8. Title of any Products purchased by You on the Platform shall
              be transferred to You post You making applicable payment for the
              same and also comply with any requirements which are applicable
              for the order
            </p>
            <br />
            <p>5. Prices of Product</p>
            <br />
            <p>
              5.1. The ownership of the Products is transferred to You upon
              delivery of Products at the address/location specified by You.
            </p>
            <br />
            <p>
              5.2. The prices of each of the Products may vary due to various
              factors and You may check the price on the Platform before placing
              an order. By placing an order You agree to pay the prices
              mentioned therein. All the Products listed on the Platform will be
              sold at Indian Rupees either at Maximum Retail Price (MRP)
              (inclusive of all taxes) or at a discounted price unless otherwise
              specified. The prices of the Products may be modified from time to
              time. The Company endeavours to make available the Products at the
              best prices as possible on the Platform. There can be an
              unintended error with respect to the Prices and other information
              of the Products. You may bring it to Our notice of such errors and
              we shall effect necessary corrections.
            </p>
            <br />
            <p>
              5.3. The Users will be informed about all the charges, fees, and
              costs (including, delivery fee) that may be levied on the purchase
              of the Products on the Platform at the checkout page during a
              transaction. The Company does not manipulate the price of any
              Products offered on the Platform.
            </p>
            <br />
            <p>
              5.4. The expression 'Lowest Prices in 10 Minutes' pertains to the
              computation of average prices for all Products offered for sale by
              the Company on the Platform, in comparison to competitors in the
              quick commerce segment for groceries, in most cases. The Company
              expressly disclaims any liability associated with the individual
              products sold on the Platform.
            </p>
            <br />
            <p>6. Delivery, delivery fee, other fee and delivery time</p>
            <br />
            <p>
              6.1. Last mile delivery charges are for the delivery services
              forming part of the sale of Products purchased by You on the
              Platform. The Company shall provide delivery of the Products upon
              purchase during such time period as communicated to You through
              the Platform. The Company endeavours to show the estimated
              delivery time for every order, however, the Company does not
              guarantee the delivery within the said time, since the exact
              delivery time of each order may vary due to various factors such
              as availability of third party delivery service providers, demand,
              traffic and weather conditions, a force majeure event, etc.
              Company endeavors to deliver the orders within 10 minutes, it may
              be possible that the delivery time may exceed then the claimed
              time due to the aforementioned reasons. Further, You can check the
              estimated time of arrival (ETA), when You go to the homepage of
              the App even before placing an order, to ensure that You are aware
              of the ETA before proceeding with placing an order. You agree that
              the Company reserves the right to charge You, fee for rain, peak
              hours and/or very high demand, late night fee, packing handling
              charges, convenience/platform fee, small cart fee, any other fee
              in addition to the delivery fee, which You can see on the view
              bill section before check out page on the App.
            </p>
            <br />
            <p>
              6.2. The delivery of the Products will be made to the delivery
              address specified by You while placing the order for the Products
              on the Platform. You are solely responsible for providing a
              complete and correct delivery address and, if applicable, further
              instructions for delivery. The Company shall not be responsible
              for any delay in delivering the order placed by You on the
              Platform caused due to the incorrect or incomplete address
              provided by You.
            </p>
            <br />
            <p>
              6.3. The goods will ordinarily be delivered when an appropriate
              person is able to receive the order at the address provided by
              You. If You request to leave a delivery unattended at Your
              address, You expressly disclaim the Company from all the
              liabilities which may arise by virtue of the Products being left
              unattended for a period of time. This includes but is not limited
              to theft, tampering, contamination, and the result of any change
              in temperature of the items which need to be kept chilled or
              frozen.
            </p>
            <br />
            <p>
              6.4. In the event You opt to make payment for the Products by
              ‘Cash on Delivery (CoD)’ mode, the Company shall have the right to
              refuse delivery of the ordered Products to You if You fail to make
              the complete payment and the Company shall treat such order as
              cancelled order and shall not be liable for any losses or damage
              that may arise on account of such non – delivery to You. The
              Company shall reserve the right to charge You, the delivery fee
              any other fees for such cancelled order and recover the same, at
              its sole discretion towards the cost incurred on such delivery
              attempt.
            </p>
            <br />
            <p>
              6.5.2. By accessing the alcoholic beverage and tobacco products
              category on the Platform, You represent that You (i) comply with
              all eligibility criteria under applicable law including legal
              drinking age in Your state of domicile and/ or where You are
              accessing the Platform and/or placing the order, (ii) have not
              been previously suspended or prohibited from accessing or
              otherwise availing the supplies from the Platform; (iii) possess
              necessary permits, if applicable; and(iv) order for Your personal
              consumption and not for resale.
            </p>
            <p>
              6.5.3. <b>Important User declaration</b>: You agree and undertake
              that You will not provide the address of any public place,
              including but not limited to, educational institution, hospital,
              religious places as Your delivery address for the order relating
              to alcoholic beverage and tobacco products. Further, You agree and
              undertake, when You buy alcohol, that You are not;
            </p>
            <p>1. in drunken condition;</p>
            <p>2. taking part in any unlawful activity;</p>
            <p>3. (uniformed official) on duty or in uniform; and</p>
            <p>4. below the legal drinking age.</p>
            <br />
            <p>
              6.5.4. The Company reserves the right to refuse delivery of such
              order to You in case of any non – compliance by You of the above
              conditions in the bona fide opinion of the Company
            </p>
            <br />
            <p>
              6.6.{" "}
              <b>
                Terms and Conditions for Purchase of Pharmaceutical Products and
                Telemedicine Consultations
              </b>
            </p>
            <br />
            <p>
              6.6.1. <b>Fresly’s Role and Disclaimer of Liability</b>
            </p>
            <p>
              Fresly facilitates the sale of drugs and pharmaceutical Products
              which shall include but shall not be limited to medical devices,
              Prescription Drugs (as defined below) and other healthcare and
              wellness products (Pharmaceutical Products). It is hereby
              clarified that that the manufacturers themselves are responsible
              for all compliances relating to the manufacturing of the
              Pharmaceutical Products including any labelling, mandatory
              declaration requirements, etc. under the applicable law including
              but not limited to the Legal Metrology Act, 2009, Drugs &amp;
              Cosmetics Act 1940, Drugs Rules 1945, Medical Devices Rules 2017,
              Cosmetics Rules 2020, the Drugs and Magic Remedies (Objectionable
              Advertisements) Act, 1954, Food Safety and Standards Act, 2006,
              Pharmacy Act,1948, Narcotics &amp; Psychotropic Act 1985 rules and
              regulations, guidelines if any framed thereunder in each of the
              aforesaid laws. In no event shall Fresly be liable for any non
              compliance on the part of manufacturers. It is also clarified that
              Pharmaceutical Products may be be oered for sale at any locations
              or areas at sole discretion of Fresly.
            </p>
            <br />
            <p>
              6.6.2. <b>Invitation to offer for sale</b>
            </p>
            <p>
              Notwithstanding anything contained elsewhere in these Terms, the
              listing of Pharmaceutical Products on the Platform by Fresly is
              merely an invitation to offer and not an oer for sale. When You
              place an order, it constitutes Your offer to enter into an
              agreement with Fresly. The acceptance of Your offer will be
              determined by resly after validation/verification of Your
              prescription (for Prescription Drugs) & ascertainment of stock
              availability (for all Pharmaceutical Products).
            </p>
            <br />
            <p>
              <b>6.6.3. Prescription Requirements</b>
            </p>
            <p>
              Users may purchase certain Pharmaceutical Products, herein
              referred to as "Prescription Drugs" provided they submit a
              prescription. These Prescription Drugs are offered for sale
              through the Platform. The prescription must be uploaded on the
              Platform where it will be transmitted for validation/verification.
              Invoices will be issued in the name appearing on the prescription.
              If any discrepancies are observed in the prescription, Fresly
              reserves the right to cancel the order immediately. Fresly retains
              the right to reject any prescription order if discrepancies or
              inaccuracies are identified. Users may be required to present the
              original prescription at delivery for verification purposes and
              also permit the third-party delivery service provider to stamp the
              original prescription; failure to do so may result in non-delivery
              of the ordered Prescription Drugs. If a prescription is uploaded
              on behalf of another individual, the User warrants that they have
              the necessary authorization to act in this capacity and assume all
              related responsibilities.
            </p>
            <br />
            <p>
              <b>6.6.7. Telemedicine Consultation services and Disclaimer</b>
            </p>
            <p>
              If the prescription uploaded by You is found to be incomplete or
              unavailable, Fresly may facilitate the consultation between You
              and TeleMedicine Consultants (as defined below) for your
              assistance. Fresly does not offer medical advice or consultations
              on health matters. All materials accessible through the Platform,
              including articles, blogs, images, and other informational
              content, serve purely academic purposes and do not substitute for
              direct professional healthcare advice. Telemedicine consultations
              available on the Platform are offered through independent
              contractors("TeleMedicine Consultants") engaged by the User solely
              at their discretion. The opinions and guidance provided by
              TeleMedicine Consultants are their own and do not represent
              Fresly's views. Users are encouraged to consult in-person
              healthcare providers as needed to confirm any information or
              recommendations received from TeleMedicine Consultants
            </p>
            <br />
            <p>
              <b>6.6.8. Limitations of Telemedicine Consultation services</b>
            </p>
            <p>
              Users acknowledge the inherent limitations of telemedicine
              consultations given that TeleMedicine Consultants cannot conduct
              physical examinations. Fresly disclaim all liability for any loss
              or damage arising from reliance on TeleMedicine Consultants and
              urges Users to verify any digital medical advice independently.
              The nature of telemedicine consultations may limit the diagnostic
              scope, making it important for Users to seek in-person
              consultations. TeleMedicine Consultants may not be able to provide
              adequate responses without receiving further medical details and
              may therefore request additional information or decline queries
              requiring in-person examination. Any falsified or misleading
              information submitted by the User renders the telemedicine
              consultation invalid. In certain instances, technological or
              security failures could compromise the confidentiality of medical
              information, which the User agrees to bear as an assumed risk of
              using the service. Telemedicine Consultation does not substitute
              or replace Your relationship with Your physician or healthcare
              provider. Any information provided by the TeleMedicine Consultants
              should not be considered a replacement for professional medical
              advice, evaluation or care from Your physician or other qualified
              healthcare professionals
            </p>
            <br />
            <p>Returns, Cancellations, and Refunds,</p>
            <br />
            <p>7.1 Returns</p>
            <br />
            <p>
              7.1.1 You may return the Product in an order, subject to the
              following, conditions:
            </p>
            <br />
            <p>
              7.1.1.1 wrong item being delivered other than what You had ordered
              in an order or
            </p>
            <br />
            <p>
              7.1.1.2 Items substantially damaged or deteriorated in quality at
              the time of delivery. You agree that You shall give Us all the
              requisite proofs including but not limited to images of Products
              having issues.
            </p>
            <br />
            <p>
              7.1.2 You shall check the Products upon delivery and initiate
              exchange or return with the requisite proofs on the Platform. You
              may exchange or return the product by contacting Our customer care
              also. IT IS HEREBY CLARIFIED THAT NO EXCHANGE OR RETURNS SHALL BE
              ACCEPTED IF THE PRODUCT PACKAGING IS OPENED OR PRODUCT IS
              USED/CONSUMED EITHER IN PART OR OTHERWISE.
            </p>
            <p>
              7.1.3 You may request exchange or return the Products, purchased
              from Us provided the Products are sealed/unopened/unused and in
              original condition and on the same day of delivery. Please note,
              the requests for exchange or returns will not be accepted from the
              day following the day when We had delivered the Product/s to You.
              We may request You to dispose off the Products for which We have
              processed a refund.
            </p>
            <br />
            <p>
              7.2 Cancellations: You may cancel an order without charge at any
              time before the Company accepts the order. You cannot cancel the
              order post acceptance of order by the Company. The Company may
              cancel an order (in full or partially) for the reasons including
              shortage or unavailability of certain Products or force majeure
              events. In case the Company cancels any order, You will not be
              charged for such cancellations, and the Company will refund You
              for any payment already made.
            </p>
            <br />
            <p>
              7.3 Refunds:. Please be informed that when You opt to cancel or
              return the Products, upon our verification of the Products and the
              documents relating thereto, the refund amount for such Products
              which are eligible for return as per these Terms, will be
              processed within a period of seven (7) business days from the date
              of us verifying and confirming the refund request. Your refund
              will be processed only when the conditions as may be stipulated by
              Us are fulfilled. The refund amount will be credited to the source
              account or in the Fresly Wallet as may be chosen by You. You
              acknowledge that after initiation of refund, it may take
              additional time for Your refund to reflect in Your account which
              is subject to Your financial institution or payment gateway
              service provider terms and conditions
            </p>
            <br />
            <p>
              7.4 The terms for acceptance of returns, cancellation and refunds
              shall be subject to reasonable additional conditions and shall be
              communicated to the User, from time to time, through the push
              notifications on the Platform, or any other mode of communication
              as specified in these Terms or as determined by the Company.
            </p>
            <br />
            <p>8. User care:</p>
            <br />
            <p>
              8.1 The warranty and guarantee, if any, applicable to the Products
              shall be that of the manufacturer and You may directly reach out
              to the manufacturer through the customer care details made
              available on the Product packaging. The Company is merely a
              reseller and disclaims any liability with respect to the
              manufacturing defects, quality, taste, performance of the Products
              sold.
            </p>
            <br />
            <p>
              8.2 You agree and understand that the Product images are
              representation of the Product and not actual image of the Product
              sold to You and You shall read the physical product label for the
              calorific and nutrition value, using instructions, batch,
              manufacture date, content, weight, manufacturer and the customer
              care details, as may be relevant, before consumption of the
              Products. WHILE EVERY REASONABLE EFFORT IS MADE TO MAINTAIN
              ACCURACY OF INFORMATION ON THE PLATFORM, ACTUAL PRODUCT PACKAGING,
              MAY CONTAIN MORE AND/OR DIFFERENT INFORMATION THAN WHAT IS SHOWN
              ON THE PLATFORM. IT IS RECOMMENDED TO REFER THE INFORMATION
              PRESENTED ON THE ACTUAL PRODUCT PACKAGING
            </p>
            <br />
            <p>
              8.3 Company reserves its right to refuse to process transactions
              by Users with a prior history of questionable transactions
              including without limitation breach of any agreements by User with
              Company or breach/violation of any law or any charges imposed by
              bank or breach of any policy without giving any reasons. Company
              may do such checks as it deem fit before approving the User's
              order for security or other reasons at the discretion of Company.
              As a result of such checks, if the Company is not satisfied with
              the credibility of the User or genuineness of the transaction, it
              will have the right to reject such an order. Company may delay the
              dispatch or cancel any transaction at its sole discretion, if it
              is suspicious of any User's authenticity or activity or if the
              User is conducting high transaction volumes, to ensure safety of
              the transaction.
            </p>
            <br />
            <p>8.4 Beware of fraud:</p>
            <br />
            <p>
              8.4.1. Please do not share Your debit/credit card number, CVV
              number, OTP, UPI/ATM pin and other sensitive information with
              anyone claiming to be a Company representative. Company or its
              authorised representatives will NEVER ask You to share the
              aforesaid details. Beware of fraudsters and please report
              incidents immediately to Your bank, the nearest police station and
              at{" "}
              <a href="https://cybercrime.gov.in/" className="underline">
                https://cybercrime.gov.in/
              </a>
              .
            </p>
            <br />
            <p>
              8.4.2. For assistance on a Fresly order or refund related issues,
              click on the ‘Get Help’ section on the App
            </p>
            <br />
            <p>
              8.4.3. Please exercise caution to verify the portals/website links
              claiming to be Fresly or a lookalike or a payment link shared over
              social media or a social messaging apps claiming to be Fresly
              discounts or oers and proactive calls from unauthorised numbers
              requesting for personal/financial information.
            </p>
            <br />
            <p>9. Use of Platform</p>
            <br />
            <p>
              9.1. Subject to compliance with the Terms, the Company hereby
              grants You a personal, non-exclusive, non-transferable, limited,
              revocable privilege to access and use the Platform. You agree to
              use the Platform only: (a) for purposes that are permitted by the
              Terms; (b) in accordance with any applicable law, regulation or
              generally accepted practices or guidelines; and (c) for availing
              the supplies through the Platform. You agree not to engage in
              activities that may adversely affect the use of the Platform by
              the Company and/or other Users.
            </p>
            <br />
            <p>
              9.2. You agree that the Platform or any portion of the Platform
              shall not be reproduced, duplicated, copied, sold, resold or
              otherwise exploited for commercial purposes
            </p>
            <br />
            <p>
              9.3. You agree to not frame or utilise the framing techniques to
              enclose any trademark, logo or any other proprietorship
              information of the Platform.
            </p>
            <br />
            <p>
              9.4. You agree not to access (or attempt to access) the Platform
              by any means other than through the interface that is provided by
              the Company. You shall not use any deep- link, robot, spider or
              other automatic device, program, algorithm or methodology, or any
              similar or equivalent manual process, to access, acquire, copy or
              monitor any portion of the Platform or Content, or in any way
              reproduce or circumvent the navigational structure or presentation
              of the Platform, materials or any Content, to obtain or attempt to
              obtain any materials, documents or information through any means
              not specifically made available through the Platform.
            </p>
            <br />
            <p>
              9.5. Further, You undertake not to host, display, upload, modify,
              publish, transmit, store, update or share any information that
            </p>
            <br />
            <p>
              9.5.1. belongs to another person and to which the User does not
              have any right;
            </p>
            <br />
            <p>
              9.5.2. is defamatory, obscene, pornographic, paedophilic, invasive
              of another's privacy, including bodily privacy, insulting, or
              harassing on the basis of gender, libellous, racially, or
              ethnically objectionable, relating or encouraging money laundering
              or gambling, or otherwise inconsistent with or contrary to the
              laws in force;
            </p>
            <br />
            <p>9.5.3. is harmful to child;</p>
            <br />
            <p>
              9.5.4. infringes any patent, trademark, copyright, or other
              proprietary rights;
            </p>
            <br />
            <p>9.5.5. violates any law for the time being in force;</p>
            <br />
            <p>9.5.6. impersonates another person;</p>
            <br />
            <p>
              9.5.7. threatens the unity, integrity, defence, security or
              sovereignty of India, friendly relations with foreign States, or
              public order, or causes incitement to the commission of any
              cognisable offence or prevents investigation of any offence or is
              insulting other nation;
            </p>
            <br />
            <p>
              9.5.8. contains software virus or any other computer code, file or
              program designed to interrupt, destroy, or limit the functionality
              of any computer resource;
            </p>
            <br />
            <p>
              9.5.9. is patently false and untrue, and is written or published
              in any form, with the intent to mislead or harass a person,
              entity, or agency for financial gain or to cause any injury to any
              person;
            </p>
            <br />
            <p>
              9.5.10. disrupt or interfere with the security of, or otherwise
              cause harm to, the Platform, systems resources, accounts,
              passwords, servers, or networks connected to or accessible through
              the Platform or any aliated or linked sites;
            </p>
            <br />
            <p>9.5.11. violate the Terms contained herein or elsewhere; and</p>
            <br />
            <p>
              9.5.12. reverse engineer, modify, copy, distribute, transmit,
              display, perform, reproduce, publish, license, create derivative
              works from, transfer, or sell any information or software obtained
              from the Platform.
            </p>
            <br />
            <p>
              9.6. You shall solely be responsible for maintaining the necessary
              computer equipment, gadgets and internet connections that may be
              required to access, use, and transact on the Platform.
            </p>
            <br />
            <p>
              9.7. You understand and acknowledge that by using the Platform or
              any of the supplies, You may encounter Content that may be deemed
              by some Users to be offensive, indecent, or objectionable, which
              Content may or may not be identified as such. You agree to use the
              Platform and any supplies at Your sole risk and that to the
              fullest extent permitted under applicable law. The Company shall
              have no liability to You for Content that may be deemed offensive,
              indecent, or objectionable to You
            </p>
            <br />
            <p>10. Intellectual Property Rights</p>
            <br />
            <p>
              10.1 The Platform and the processes, and their selection and
              arrangement, including but not limited to, all text, videos,
              graphics, user interfaces, visual interfaces, sounds and music (if
              any), artwork and computer code (and any combinations thereof)
              (collectively, the “Content”) on the Platform is licensed to the
              Company and the design, structure, selection, coordination,
              expression, look and feel and arrangement of such Content is
              protected by copyright, patent and trademark laws, and various
              other intellectual property rights.
            </p>
            <br />
            <p>
              10.2 The trademarks, logos and service marks displayed on the
              Platform (“Marks”) are the licensed property of the Company or
              owned by third parties. You are not permitted to use the Marks
              without the prior consent of the Company or the relevant third
              party (which is the owner of the Marks) respectively . Access or
              use of the Platform does not authorise any party to use
              trademarks, logo, or any other mark in any manner.
            </p>
            <br />
            <p>
              10.3 The Company disclaims to hold any right, title, or interest
              in and to the intellectual property rights arising out of or
              associated with the Products.
            </p>
            <br />
            <p>
              10.4 References on the Platform of any name, mark, services or
              products of third parties has been provided after the express
              consent of such third party for Your convenience, and in no way
              constitutes an endorsement, sponsorship or recommendation, whether
              express or implied.
            </p>
            <br />
            <p>11. Disclaimer of Warranties & Liability</p>
            <br />
            <p>
              11.1 You expressly understand and agree that, to the maximum
              extent permitted by applicable law:
            </p>
            <br />
            <p>
              11.1.1 The Platform and other Content are provided by the Company
              on an “as is” basis without warranty of any kind, express,
              implied, statutory, or otherwise, including the implied warranties
              of title, non-infringement, merchantability, or fitness for a
              particular purpose. Without limiting the foregoing, the Company
              makes no warranty that the Platform or supplies will meet Your
              requirements, or Your use of the Platform will be uninterrupted,
              timely, secure, or error-free. No advice or information, whether
              oral or written, obtained by You from the Company shall create any
              warranty not expressly stated in the Terms.
            </p>
            <br />
            <p>
              11.1.2 Company will not be liable for any loss that You may incur
              as a consequence of unauthorized use of Your Account or Account
              information in connection with the Platform either with or without
              Your knowledge.
            </p>
            <br />
            <p>
              11.1.3 Company has endeavoured to ensure that all the information
              on the Platform is correct, but the Company neither warrants nor
              makes any representations regarding the quality, accuracy or
              completeness of any data, information regarding the supplies or
              otherwise. The Company shall not be responsible for the delay or
              inability to use the Platform or related functionalities, the
              provision of or failure to provide functionalities, or for any
              information, software, functionalities, and related graphics
              obtained through the Platform, or otherwise arising out of the use
              of the Platform, whether based on contract, tort, negligence,
              strict liability or otherwise. Further, the Company shall not be
              held responsible for non-availability of the Platform during
              periodic maintenance operations or any unplanned suspension of
              access to the Platform that may occur due to technical reasons or
              for any reason beyond the Company’s reasonable control.
            </p>
            <br />
            <p>
              11.2 While the Company has made best efforts to display the
              colours of the Product image on the Platform as accurately as
              possible. However, the actual colours of the Products You see will
              depend on Your monitor or device, and the Company does not provide
              any guarantee in respect of such display and will not be
              responsible or liable for the same.
            </p>
            <br />
            <p>
              11.3 The Company makes no representation that the Content on the
              Platform is appropriate to be used or accessed outside the
              Republic of India. Any Users who use or access the Platform from
              outside the Republic of India, do so at their own risk and are
              responsible for compliance with the laws of such jurisdiction.
            </p>
            <br />
            <p>
              11.4 The Terms do not constitute, nor may the Terms be used for or
              in connection with any promotional activities or solicitation by
              anyone in any jurisdiction in which such promotional activities or
              solicitation are not authorized or to any person to whom it is
              unlawful to promote or solicit
            </p>
            <br />
            <p>12. Indemnification and Limitation of Liability</p>
            <br />
            <p>
              12.1 You agree to indemnify, defend and hold harmless Fresly and
              its affliates including but not limited to its (and its
              affliates’) officers, directors, consultants, agents,
              representatives and employees; and its third party partners
              (“Indemnitees”) from and against any and all losses, liabilities,
              claims, damages, demands, costs and expenses (including reasonable
              legal fees) asserted against or incurred by the Indemnitees that
              arise out of, result from, or may be payable by virtue of, any
              breach or non-performance of any representation, warranty,
              covenant or agreement made or obligation to be performed by You
              pursuant to these Terms and/or the Privacy Policy. Further, You
              agree to hold the Indemnitees harmless against any claims made by
              any third party due to, or arising out of, or in connection with,
              Your use of the Platform, any misrepresentation with respect to
              the data or information provided by You, Your violation of the
              Terms and/or the Privacy Policy, Your violation of applicable
              laws, or Your violation of any rights of another, including any
              intellectual property rights.
            </p>
            <br />
            <p>
              12.2 In no event shall the Company and its directors, ocers,
              partners, consultants, agents, and employees and its third party
              partners, be liable to You or any third party for any special,
              incidental, indirect, consequential, or punitive damages
              whatsoever, arising out of or in connection with Your use of or
              access to the Platform or Content on the Platform.
            </p>
            <br />
            <p>
              12.3 The limitations and exclusions in this Section apply to the
              maximum extent permitted by applicable laws.
            </p>
            <br />
            <p>13. Violation of these Terms</p>
            <br />
            <p>
              13.1 You agree that any violation by You of these Terms will
              likely cause irreparable harm to the Company, for which monetary
              damages would be inadequate, and You consent to the Company
              obtaining any injunctive or equitable relief that they deem
              necessary or appropriate in such circumstances. These remedies are
              in addition to any other remedies that the Company may have at law
              or in equity.
            </p>
            <br />
            <p>14. Suspension and Termination</p>
            <br />
            <p>
              14.1 The Terms will continue to apply until terminated by either
              You or the Company as set forth below. If You object to the Terms
              or are dissatisfied with the Platform, You may(i) close Your
              Account on the Platform; and/or (ii) stop accessing the Platform.
            </p>
            <br />
            <p>
              14.2 The Company may disable Your access or block Your future
              access to the Platform or suspend or terminate Your Account if it
              believes, in its sole and absolute discretion, that You have
              violated any term of these Terms or the Privacy Policy or in any
              way otherwise acted unethically. Notwithstanding anything in this
              Clause, all terms which by their nature are intended to survive
              such termination, will survive indefinitely unless and until the
              Company chooses to terminate them.
            </p>
            <br />
            <p>
              14.3 Any such termination under clause 14.1 and 14.2 above shall
              not cancel Your obligation to pay for a Product purchased on the
              Platform, or any other obligation which has accrued, or is
              unfulfilled and relates to the period, prior to termination.
            </p>
            <br />
            <p>
              14.4 You shall be liable to pay any fees or charges, if applicable
              in respect of the supplies until the date of termination by either
              party whatsoever.
            </p>
            <br />
            <p>15. Governing Law and Jurisdiction</p>
            <br />
            <p>
              15.1 These Terms shall be governed by and constructed in
              accordance with the laws of India without reference to conflict of
              laws principles and disputes arising in relation hereto shall be
              subject to the exclusive jurisdiction of courts at Mumbai, India.
            </p>
            <br />
            <p>16. Grievance Redressal Mechanism</p>
            <br />
            <p>
              16.1.1 For any order related issue, You may first reach out to Us
              via chat support on the App for real time basis resolution.
            </p>
            <br />
            <p>
              16.1.2 You may also write to Us at support@Fresly.in and We will
              strive to resolve Your order related grievance within the
              timelines prescribed under applicable laws.
            </p>
            <br />
            <p>
              16.1.3 If You still have any grievances, or complaints or concerns
              with respect to the Platform or order or are not satisfied with
              the resolution, the Content, or the supplies, You can contact the
              designated Grievance cum Nodal Ocer of the Company, can be
              accessed [here]. Details of the Grievance cum Nodal Ocer is
              provided in compliance of (1) Information Technology Act, 2000 and
              rules made there under, and (2) Consumer Protection (E-Commerce)
              Rules 2020, as amended time to time.
            </p>
            <br />
            <p>
              16.2 The Grievance Officer of the Company shall endeavour to
              acknowledge the User grievances, or complaints or concerns with
              respect to the Platform, the Content, or the supplies, within 48
              hours of receipt of the same and shall endeavour to redresses the
              same at the earliest and in no event later than 30 (thirty) days
              of receipt of such request. By lodging a complaint or grievance,
              You agree to provide complete support to the Grievance Ocer and
              such reasonable information as may be sought by them from You
            </p>
            <br />
            <p>17. Communications</p>
            <br />
            <p>
              17.1 You hereby expressly agree to receive communications by way
              of SMS, telephone or VOIP calls, messaging app like WhatsApp on
              the registered mobile phone number /or electronic communications
              like e-mails from the Company and other third parties duly
              authorised by the Company. You hereby expressly consent to the
              monitoring and recording, by the Company of any and all
              communications between You and the Company or its agents,
              employees, consultants, contractors, or representatives of the
              Company or of their authorised partners, and such monitoring or
              recording waives any further notice or consent requirement under
              the applicable laws.
            </p>
            <br />
            <p>
              17.2 You can unsubscribe or opt-out from receiving communications
              from the Company. In which case, the Company will only send
              communications solely required for the purposes of availing the
              supplies by You.
            </p>
            <br />
            <p>18. General Provisions</p>
            <br />
            <p>
              18.1 Notice: All notices from the Company will be served by email
              to Your registered email address or by messaging app on the
              registered mobile phone number or by general notification on the
              Platform.
            </p>
            <br />
            <p>
              18.2 Assignment: You cannot assign or otherwise transfer any
              rights granted hereunder to any third party. The Company’s rights
              under the Terms are freely transferable by the Company to its
              successor or any third party without the requirement of seeking
              Your consent.
            </p>
            <br />
            <p>
              18.3 Severability: If, for any reason any provision of the Terms,
              or any portion thereof, to be unenforceable, that provision shall
              be enforced to the maximum extent permissible so as to give effect
              to the intent of the parties as reflected by that provision, and
              the remainder of the Terms shall continue in full force and
              effect.
            </p>
            <br />
            <p>
              18.4 Force Majeure: The Company and/or the Platform shall not be
              liable to You for its failure to perform or for delay in providing
              You access to Your Account or to the Platform or any supplies
              thereof, to the extent such failure or delay results from causes
              beyond its reasonable control, including, without limitation, acts
              of God, fires, explosions, wars or other hostilities,
              insurrections, revolutions, strikes, labour unrest, earthquakes,
              floods, pandemic, epidemics or regulatory or quarantine
              restrictions, unforeseeable governmental restrictions or controls
              or a failure by a third party hosting provider or internet service
              provider or on account of any change or defect in the software
              and/or hardware of Your computer system.
            </p>
            <br />
            <p>19. IP Infringement</p>
            <br />
            <p>
              19.1 If You believe the Platform violates Your intellectual
              property, You must promptly notify the Company in writing at
              legal@Fresly.in. These notifications should only be submitted by
              the owner of the intellectual property, or an agent authorized to
              act on his/her behalf. However, any false claim by You may result
              in the termination of Your access to the Platform.
            </p>
            <br />
            <p>
              19.2 You are required to provide the following details in Your
              notice:
            </p>
            <br />
            <p>
              19.2.1 the intellectual property that You believe is being
              infringed with proof of Ownership;
            </p>
            <br />
            <p>
              19.2.2 the item that You think is infringing and include suffcient
              information about where the material is located on the Platform;
            </p>
            <br />
            <p>
              19.2.3 a statement that You believe in good faith that the item
              You have identified as infringing is not authorized by the
              intellectual property owner, its agent, or the law to be used in
              connection with the Platform;
            </p>
            <br />
            <p>
              19.2.4 Your contact details, such as Your address, telephone
              number, and/or email;
            </p>
            <br />
            <p>
              19.2.5 a statement, under penalty of perjury, that the information
              You provided in Your notice is accurate, and that You are the
              intellectual property owner, or an agent authorized to act on
              behalf of the intellectual property owner whose intellectual
              property is being infringed; and
            </p>
            <br />
            <p>19.2.6 Your physical or electronic signature.</p>
            <br />
            <p>
              19.2.7 Please note: Company shall not consider an incomplete
              request.
            </p>
            <br />
            <p>20. Advertisements</p>
            <br />
            <p>
              20.1. As part of the supplies provided by Us; We may facilitate
              and allow third party advertisers (“
              <b>Third Party Advertisers </b>”) to place advertisements on the
              Platform. You understand that any content put out by Third Party
              Advertisers is not edited, reviewed or otherwise endorsed by the
              Company and we disclaim to the fullest extent permitted by law any
              liability for the content published by the Third Party Advertisers
            </p>
            <br />
            <p>
              20.2. To the extent You are a Third Party Advertiser You
              understand that in addition to these Terms You will also be
              required to agree to the Company’s policies and other contractual
              agreements that You will need to execute for placing Your
              advertisement. As a general principle the content in the
              advertisements should not be misleading or in violation of
              applicable law or guidelines issued by the Advertising Standards
              Council of India or any other self-regulating body. You also
              acknowledge that we have the sole right at our discretion to
              remove any Third Party Advertisement or require You to prove
              factual substantiation if we are of the view that it is in
              violation of applicable law or any self-regulating industry body
              guidelines or is otherwise misleading.
            </p>
            <br />
            <p>
              20.3. If You are of the view that the content of a third party
              advertiser is inappropriate or in violation of applicable law,
              please write to us at the email address provided above.
            </p>
          </div>
        ) : page === "Privacy Policy" ? (
          <div className="mt-5">
            <p>
              <b>
                Fresly (“Fresly”),a Azamgarh U.P baised Store as the owner of
                the brand Fresly and App based platform ‘Fresly.in’ and mobile
                application ‘Fresly’ (collectively, the“Platform”). The
                ‘Company’ is a licensee of the brand Fresly and the Platform,
                and is responsible for operating and managing the Platform under
                the license. You can access the complete Company details
              </b>
            </p>
            <br />
            <p>
              This privacy policy (Privacy Policy) describes the policies and
              procedures applicable to the collection, use, disclosure and
              protection ofYour information shared with Us while You use the
              Platform, and for the purpose of this Privacy Policy "<b>We</b>",
              "<b>Us</b>", or "<b>Our</b>" refers to any of the Company or
              Fresly or both of them, wherever context so require and the terms{" "}
              <b>“You”</b>, <b>“Your”</b>,<b>“Yourself”</b> or <b>“User”</b>{" "}
              refer to user of the Platform. We value the trust You place in Us.
              That is why,We maintain reasonable security standards for securing
              the transactions andYour information. This Privacy Policy is an
              electronic record under the Information Technology Act, 2000 and
              the rules made thereunder. This Privacy Policy does not require
              any physical, electronic, or digital signature by Us and has been
              published in accordance with the provisions of the Information
              Technology Act, 2000 and the rules made thereunder that require
              publishing the privacy policy and terms of use on thePlatform.
            </p>
            <br />
            <p>
              Please read the Privacy Policy carefully prior to using or
              registering on the Platform or accessing any material, information
              or availing any Services through the Platform
            </p>
            <br />
            <p>
              This Privacy Policy specifies the manner in which Your information
              is collected, received, stored, processed, disclosed, transferred,
              dealt with or otherwise handled by Us. This Privacy Policy does
              not apply to information that You provide to, or that is collected
              by, any third-party through the Platform, and any Third- Party
              Sites that You access or use in connection with the Services oered
              on the Platform.
            </p>
            <br />
            <p>
              By visiting the Platform or setting up/creating an user account
              (Account) on the Platform, You accept and agree to be bound by the
              terms and conditions of this Privacy Policy and consent to Us
              collecting, storing, processing, transferring and sharing
              information includingYour Personal Information (defined below)in
              accordance with this Privacy Policy.
            </p>
            <br />
            <p>
              Further, in case You are under the age of 18 years, You (i) accept
              and acknowledge that You are accessing the Platform through a
              parent or a legal guardian who is of a
            </p>
            <br />
            <p>
              legal age to form a binding contract under the Indian Contract
              Act, 1872 and such person has accepted this Privacy Policy on Your
              behalf to bind You; and (ii) hereby acknowledge that You are
              accessing this Platform under the supervision of Your parent or
              legal guardian and have their express permission to use the
              Services.
            </p>
            <br />
            <p>
              We may update this Privacy Policy in order to comply with the
              regulatory or administrative requirements. If We make any
              significant changes to this Privacy Policy, We will endeavour to
              provide You with reasonable notice of such changes, such as via
              prominent notice on the Platform or any other communication
              channels on record. To the extent permitted under applicable law,
              Your continued use of the Services after We publish or send a
              notice about the changes to this Privacy Policy shall constitute
              Your consent to the updatedPrivacy Policy.
            </p>
            <br />
            <p>
              This Privacy Policy is incorporated into and subject to the terms
              of use available on the Platform (“Terms”) and shall be read
              harmoniously and in conjunction with the Terms. All capitalised
              terms used herein however not defined under this Privacy Policy
              shall have the meaning ascribed to them under the Terms.
            </p>
            <br />
            <p>
              1) Collection of Information: We collect various information from
              You when You access or visit the Platform, register or set up an
              Account on the Platform or use the Platform. You may browse
              certain sections of the Platform and the Content, without
              registering an Account on the Platform. However, to avail certain
              Services on the Platform, You are required to set up an Account on
              the Platform. This Privacy Policy applies to information(s), as
              mentioned below and collected about You:
            </p>
            <br />
            <p>
              a) <b>Personal Information</b>: You may provide certain
              information to Us voluntarily while registering on the Platform
              for availing Services including but not limited to Your complete
              name, mobile number, email address, date of birth, gender, age,
              address details, proof of identity such as Permanent Account
              Number (PAN), passport, driving license, the voter's identity card
              issued by the Election Commission of India, or any other document
              recognized by the Government for identification, and any other
              information voluntarily provided through the Platform (“
              <b>Personal Information</b>”). The act of providing Your Personal
              Information is voluntary in nature and We hereby agree and
              acknowledge that We will collect, use, store, transfer, deal with
              and share such details in compliance with applicable laws and this
              Privacy Policy.
            </p>
            <br />
            <p>
              b) <b>Sensitive Personal Information</b>: For the purpose of this
              PrivacyPolicy, Sensitive Personal Information consists of
              information relating to the following
            </p>
            <p>i) passwords;</p>
            <p>
              ii) financial information such as bank account or credit card or
              debit card or other payment instrument details;
            </p>
            <br />
            <p>iii) physical, physiological and mental health condition</p>
            <p>iv) sexual orientation</p>
            <p>v) medical records and history;</p>
            <p>vi) biometric information;</p>
            <p>
              vii) any details relating to the above as provided to a body
              corporate for providing services; and
            </p>
            <p>
              viii) any details relating to the above, received by a body
              corporate for processing, stored or processed under lawful
              contract or otherwise.
            </p>
            <p>
              ix) any other information that may be regarded as
              SensitivePersonal Information” as per the prevailing law for the
              time being in force.
            </p>
            <p>
              Provided that, any information that is freely available or
              accessible in public domain or furnished under the Right to
              Information Act, 2005 or any other law for the time being in force
              shall not be regarded as Sensitive Personal Information.
            </p>
            <p>
              You may be asked for the payment details to process payments for
              the Services. You may also be asked to provide certain additional
              information about Yourself on a case to case basis.
            </p>
            <p>
              c) <b>Transactional Information</b>: If You choose to avail the
              Services through the Platform, We will also collect and store
              information about Your transactions including transaction status,
              order history, number of transactions, details and Your behaviour
              or preferences on the Platform. All transactional information
              gathered by Us shall be stored on servers, log files and any other
              storage system owned by any of Us or by third parties.
            </p>
            <br />
            <p>
              d) <b>Location based information</b>: When and if You download
              and/or use the Platform through Your mobile, tablet, and/or any
              other computer sources or electronic devices, We may receive
              information about Your location, Your IP address, including a
              unique identifier number for Your device. The informationYou
              provide may be used to provide You with location-based Services
              including but not limited to search results and other personalized
              content. If You permit the Platform to access Your location
              through the permission system used by Your device operating
              system, the precise location of Your device when the Platform is
              running in the foreground or background may be collected. You can
              withdraw Your consent at any time by disabling the location
              tracking functions on Your device. However, this may affect Your
              experience of certain functionalities on the Platform. In addition
              to the above, Your IP address is identified and used to also help
              diagnose problems with Our server, resolve such problems and
              administer the Platform. Your IP address is also used to help
              identify You and to gather broad demographic information.
            </p>
            <br />
            <p>
              The primary goal in doing so is to provide You a safe, effcient,
              smooth, and customized experience on the Platform. The information
              collected allows Us to provide the Services and/or features on the
              Platform that most likely meet Your needs, and to customize the
              Platform to make Your experience safer and easier. More
              importantly, while doing so, We collect the above - mentioned
              Personal Information from You that We consider necessary for
              achieving this purpose.
            </p>
            <br />
            <p>
              We may also collect certain non-personal information, such as Your
              internet protocol address, web request, operating system, browser
              type, other browsing information (connection, speed, connection
              type etc.), device type, the device's telephone number, URL,
              internet service provider, aggregate user data, software and
              hardware attributes, the URL of the previous website visited by
              You, list of third-party applications being used by You, pages You
              request, and cookie information, etc. which will not identify with
              You specifically, the activities conducted by You(“Non - Personal
              Information”),while You browse, access or use the Platform. We
              receive and store Non-PersonalInformation by the use of data
              collection devices such as “cookies” on certain pages of the
              Platform for various purposes, including authenticating Users,
              store information (including on Your device or in Your browser
              cache) about Your use of our Services, remembering User
              preferences and settings, determining the relevancy of content,
              delivering and measuring the promotional effectiveness, and
              promote trust and safety, analyzing site traffic and trends, and
              generally understanding the online behaviors and interests of
              people. Certain additional features may be offered on the Platform
              that are only available through use of a “cookie”. We place both
              permanent and temporary cookies in Your device. We may also use
              cookies from third party partners for marketing and analytics
              purposes
            </p>
            <br />
            <p>
              You are always free to decline such cookies if Your browser
              permits, although in that case, You may not be able to use certain
              features or Services being provided on the Platform.
            </p>
            <br />
            <p>
              In general, You can browse the Platform without telling Us who You
              are or revealing any Personal Information about Yourself. In such
              a case, We will only collect and store the Non -Personal
              Information. Once You give us Your Personal Information, You are
              not anonymous to Us. Wherever possible, while providing the
              information to Us, We indicate which fields are mandatory and
              which fields are optional for You. You always have the option to
              not provide the Personal Information to Us through the Platform by
              choosing to not use a particular Service or feature being provided
              on the Platform, which requires You to provide such information.
              We may automatically track certain information about You based
              upon Your behaviour on the Platform. We use this information to do
              internal research on Your demographics, interests, and behaviour
              to better understand, protect and prove service to You. This
              information is compiled and analyzed by Us on an aggregated basis
              and not individually, in a manner that does not specifically
              identify You.
            </p>
            <br />
            <p>
              If You choose to post messages on the Platform’s message boards,
              chat rooms or other message areas or leave feedback, We will
              collect and store such information You provide to Us. We retain
              this information as necessary to resolve disputes, provide
              customer support, respond to queries, and inquiries, and
              troubleshoot problems and improve the Services.
            </p>
            <br />
            <p>
              If You send us correspondence, such as emails or letters, or if
              other users or third parties send us correspondence about Your
              activities or postings on the Platform, We may collect and retain
              such information into a file specific to You for responding to
              Your request and addressing concerns in relation to Your use of
              the Platform.
            </p>
            <br />
            <p>
              We shall be entitled to retain YourPersonal Information and other
              information for such duration as may be required for the purposes
              specified hereunder and will be used only in accordance with this
              Privacy Policy.
            </p>
            <br />
            <p>
              2) Use of information: We use the information, for the following:
            </p>
            <br />
            <p>
              a) to provide and improve the Services on the Platform that You
              request;
            </p>
            <p>
              b) for internal business purposes and services, including without
              limitation, warehousing services, delivery services, IT support
              services, and data analysis services
            </p>
            <p>
              c) to resolve disputes, administer our service and
              diagnose/troubleshoot technical problems;
            </p>
            <p>
              d) to help promote a safe service on the Platform and protect the
              security and integrity of the Platform, the Services and the
              users;
            </p>
            <p>
              e) to design and improve the products and Services and customer
              relationship management processes
            </p>
            <p>f) to collect money from You in relation to the Services,</p>
            <p>
              g) to inform You about online and offline offers, products,
              services, and updates;
            </p>
            <p>
              h) to customize Your experience on the Platform or share marketing
              material with You
            </p>
            <p>
              i) to detect, prevent and protect Us from any errors, fraud and
              other criminal or prohibited activity on the Platform
            </p>
            <p>j) to enforce and inform about Our Terms;</p>
            <p>
              k) to process and fulfil Your request for Services or respond to
              Your comments, and queries on the Platform
            </p>
            <p>
              l) to contact You through email, SMS, WhatsApp, telephone and any
              other mode of communication in relation to the Services;
            </p>
            <p>
              m) to allow Our service providers, business partners and/or
              associates to present customized messages to You;
            </p>
            <p>
              n) to communicate important notices or changes in the Services,
              use of the Platform and the Terms/policies which govern the
              relationship between You and the Company and/or Fresly, as
              applicable
            </p>
            <p>
              o) to conform to the legal requirements, compliance/reporting to
              regulatory authorities, as may be required and to comply with
              applicable laws;
            </p>
            <p>
              p) to carry out Our obligations and enforce Our rights arising
              from any contract entered into between You and Us; and
            </p>
            <p>q) to carry out research with relevant partners.</p>
            <p>
              r) for any other purpose after obtaining Your consent at the time
              of collection.
            </p>
            <br />
            <p>
              (collectively <b>“Purposes”</b>)
            </p>
            <br />
            <p>
              The Company and/or Fresly may occasionally ask You to complete
              optional online surveys. These surveys may ask You for Your
              contact information and demographic information (like pin code, or
              age). We use this information to tailor Your experience on the
              Platform, providing You with content that We think You might be
              interested in and to display content according to Your
              preferences. We use Your information to send You promotional
              emails, however, We will provide You the ability to opt-out of
              receiving such emails from Us. However, You will not be able to
              opt-out of receiving administrative messages, customer service
              responses or other transactional communications. We will not share
              YourPersonal Information with another user of the Platform and
              vice versa without Your express consent.
            </p>
            <br />
            <p>
              3) <b>Sharing of Information</b>
            </p>
            <br />
            <p>
              a) Third Party Service Providers: We may disclose Your
              PersonalInformation to third party vendors, delivery partners,
              consultants, partners for carrying out research and other service
              providers who work for either of Us or provide Services through
              the Platform and are bound by contractual obligations to keep such
              Personal Information confidential and use it only for the purposes
              for which We disclose it to them and maintain the same level of
              data protection that is adhered to by Us. This disclosure may be
              required, for instance, to provide You access to the Services and
              process payments including validation of Your Payment Details, to
              facilitate and assist marketing and advertising
              activities/initiatives, for undertaking auditing or data analysis,
              or to prevent, detect, mitigate, and investigate fraudulent or
              illegal activities related to the Services. You expressly consent
              to the sharing of Your information with third party service
              providers, including payment gateways, to process payments and
              manage Your payment- related information. We do not disclose Your
              Personal Information to third parties for their marketing and
              advertising purposes without Your explicit consent.
            </p>
            <br />
            <p>
              b) Compliance with law: We may disclose Your information including
              Personal Information, to the extent necessary: (a) to comply with
              laws, regulatory requirements and to respond to lawful requests
              and legal process or an investigation, (b) to protect Our rights
              and property, the users, and others, including to enforce the
              Terms or to prevent any illegal or unlawful activities, and (c) in
              an emergency to protect Our personal safety and assets the users,
              or any person. In all such events We shall in no manner be
              responsible for informing You or seeking Your approval or consent.
              We may also share aggregated anonymized (and de-identified)
              information with third parties at Our discretion.
            </p>
            <br />
            <p>
              c) Acquisition Sale or Merger: We may, in compliance with
              applicable laws, share all of Your Personal Information (including
              SensitivePersonal Information) and other information with any
              other businessentity(ies), in the event of a merger, sale,
              reorganisation, amalgamation, joint ventures, assignment,
              restructuring of business or transfer or disposition of all or any
              portion of any of Us.
            </p>
            <br />
            <p>
              d) Sharing of information with third party for operation of
              thePlatform: In the event the license agreement is terminated or
              the territory of the license is modified or limited in any manner,
              We may share any or all Your Personal Information (including
              Sensitive Personal Information, if any) and Non-Personal
              Information with the incoming licensee continuity of smooth
              operation of the Platform and seamless user experience
            </p>
            <p>
              e) Sharing of information with any member of Our Group or
              affiliated entities, third parties and transfer outside India:
              Subject to applicable law, we may at Our sole discretion, share
              Personal Information(including Sensitive Personal Information) to
              Our Group or affiliated entities, any third party that ensures at
              least the same level of data protection as is provided by Us under
              the terms hereof, located in India or any other country. By using
              the Platform, You accept the terms hereof and hereby consent to
              Us, sharing of Your Personal Information and Sensitive Personal
              Information to Our Group or affiliated entities, third parties,
              including in any location outsideIndia, provided that they ensure
              that Your Personal Information and Sensitive Personal Information
              is protected in compliance with standards that are comparable to
              the standards of protection afforded to it in India.
            </p>
            <br />
            <p>
              For the purpose of this clause the term “Group” shall mean, with
              respect to any person, any entity that is controlled by such
              person, or any entity that controls such person, or any entity
              that is under common control with such person, whether directly or
              indirectly, including any Relative or Related Party (as such term
              defined in the Companies Act, 2013 to the extent applicable) of
              such person holding, subsidiary Companies, etc.
            </p>
            <br />
            <p>
              You also specifically agree and consent to Us collecting, storing,
              processing, transferring, and sharing information (including
              Personal Information and Sensitive Personal Information)related to
              You with third parties such as with entities registered under
              applicable laws including payment gateways and aggregators, solely
              for Us to provide Services to You including processing Your
              transaction requests for theServices or to improve Your experience
              on the Platform.
            </p>
            <br />
            <p>
              4) <b>Security Precautions and Measures</b>: The Platform has
              reasonable security measures and safeguards in place to protect
              Your privacy and Personal Information from loss, misuse,
              unauthorized access, disclosure, destruction, and alteration, in
              compliance with applicable laws. Further, whenever You change or
              access Your Account on the Platform or any information relating to
              it, the use of a secure server is offered. It is further clarified
              that You have and so long as You access and/or use the Platform
              (directly or indirectly)the obligation to ensure that You shall at
              all times take adequate physical, managerial, and technical
              safeguards, at Your end, to preserve the integrity and security of
              Your data which shall include and not be limited to Your Personal
              Information.
            </p>
            <br />
            <p>
              You will be responsible for maintaining the confidentiality of the
              Account information and are fully responsible for all activities
              that occur under Your Account. You agree to (a)immediately notify
              Us of any unauthorized use of Your Account information or any
              other breach of security, and (b) ensure that You exit from Your
              Account at the end of each session. We cannot and will not be
              liable for any loss or damage arising from Your failure to comply
              with this provision. You may be held liable for losses incurred by
              any of Us or any other user of or visitor to thePlatform due to
              authorized or unauthorized use of Your Account as a result of Your
              failure in keeping Your Account information secure and
              confidential.
            </p>
            <br />
            <p>
              When payment information is being transmitted on or through the
              Platform, it will be protected by encryption technology. Hence, we
              cannot guarantee that transmissions of Your payment-related
              information or Personal Information will always be secure or that
              unauthorized third parties will never be able to defeat the
              security measures taken by Us or Our third-party service
              providers. We assume no liability or responsibility for disclosure
              of Your information due to errors in transmission, unauthorized
              third-party access, or other causes beyond Our control. You play
              an important role in keeping Your Personal Information secure. You
              shall not share Your Personal Information or other security
              information for Your Account with anyone. We have undertaken
              reasonable measures to protect Your rights of privacy with respect
              to Your usage of thePlatform and the Services. However, We shall
              not be liable for any unauthorized or unlawful disclosures of Your
              Personal Information made by any third parties who are not subject
              to Our control.
            </p>
            <br />
            <p>
              Notwithstanding anything contained in this Privacy Policy or
              elsewhere, We shall not be held responsible for
            </p>
            <br />
            <p>
              Notwithstanding anything contained in this Privacy Policy or
              elsewhere, We shall not be held responsible for
            </p>
            <br />
            <p>
              a) any security breaches on third-party websites or applications
              or for any actions of third-parties that receive Your
              PersonalInformation; or
            </p>
            <p>
              b) any loss, damage or misuse of Your Personal Information, if
              such loss, damage or misuse is attributable to a Force Majeure
              Event. For the purpose of this Privacy Policy, a “Force Majeure
              Event” shall mean any event that is beyond Our reasonable control
              and shall include, acts of God, fires, explosions, wars or other
              hostilities, insurrections, revolutions, strikes, labour unrest,
              earthquakes, floods, pandemic, epidemics or regulatory or
              quarantine restrictions, unforeseeable governmental restrictions
              or controls or a failure by a third party hosting provider or
              internet service provider or on account of any change or defect in
              the software and/or hardware of Your computer system.
            </p>
            <br />
            <p>
              5) Retention of Your Personal Information: We maintain records of
              Your Personal Information only till such time it is required for
              the Purposes, or for as long as required by applicable law. When
              You request us to delete Your information, we will honour the said
              request, but we may retain certain information about you for the
              purposes authorized under thisPrivacy Policy unless prohibited by
              law.
            </p>
            <br />
            <p>
              6){" "}
              <b>
                Links to Other Third–Party Sites and collection of information
              </b>
              : The Platform may link You to other third-party Platforms(“
              <b>Third-Party Sites</b>”) that may collect Your Personal
              Information including Your IP address, browser specification, or
              operating system. We are not in any manner responsible for the
              security of such information or their privacy practices or content
              of those Third-Party Sites. Additionally, You may also encounter
              “cookies” or other similar devices on certain pages of the
              Third-Party Sites and it is hereby clarified that the Platform
              does not control the use of cookies by these Third-Party Sites.
              These third-party service providers and Third-Party Sites may have
              their own privacy policies governing the storage and retention of
              Your information that You may be subject to. This Privacy Policy
              does not govern any information provided to, stored on, or used by
              these third-party providers and Third-Party Sites. We recommend
              that when You enter a Third-Party Site, You review the Third-Party
              Site’s privacy policy as it relates to safeguarding of Your
              information. We may use third-party advertising companies to serve
              ads when You visit the Platform. These companies may use
              information (not includingYour name, address, email address, or
              telephone number) about Your visits to the Platform and
              Third-Party Sites in order to provide advertisements about goods
              and services of interest to You. You agree and acknowledge that We
              are not liable for the information published in search results or
              by any Third-Party Sites.
            </p>
            <br />
            <p>
              7) Public Posts: You may provide Your feedback, reviews,
              testimonials, etc. on the Platform on Your use of the Services
              (“Posts”). Any content or PersonalInformation and Posts that You
              share or upload on the publicly viewable portion of the Platform
              (on discussion boards, in messages and chat areas, etc.) will be
              publicly available, and can be viewed by other users and any and
              all intellectual property rights in and to such Posts shall vest
              with Us. Your Posts shall have to comply with the conditions
              relating to Posts as mentioned in the Terms. We shall retain an
              unconditional right to remove and delete any Post or such part of
              the Post that, in Our opinion, does not comply with the conditions
              in the Terms or where applicable law requires us to remove such
              Post. We reserve the right to use, reproduce and share Your Posts
              for any purpose. If You delete Your Posts from the Platform,
              copies of such Posts may remain viewable in archived pages, or
              such Posts may have been copied or stored by other Users.
            </p>
            <br />
            <p>
              8){" "}
              <b>Your Consent, Rectification and Changes to Privacy Policy</b>:
            </p>
            <br />
            <p>
              <b>Your acknowledgement</b>: All information disclosed by You
              shall be deemed to be disclosed willingly and without any
              coercion. No liability pertaining to the authenticity /
              genuineness / misrepresentation /fraud / negligence of the
              information disclosed shall lie on Us nor will We be in any way
              responsible to verify any information obtained from You
            </p>
            <br />
            <p>
              Withdrawal of consent: You may choose to withdraw Your consent
              provided hereunder at any point in time. You may do the same by
              visiting Home page {"- >Settings Icon->"} Profile on the mobile
              application.
            </p>
            <br />
            <p>
              In case You do not provide Your consent or later withdraw Your
              consent, We request You not to access thePlatform, Content or use
              the Services. We also reserve the right to not provideYou any
              Services and/or Content on the Platform and/or features of the
              Platform once You withdraw Your consent. In such a scenario, We
              will delete Your information (personal or otherwise)or de-identify
              it so that it is anonymous and not attributable to You. In the
              event, We retain the Personal Information post withdrawal or
              cancellation of Your consent, it shall retain it only for the
              period permitted under applicable laws.
            </p>
            <br />
            <p>
              You should be aware that some of the Personal Information that may
              have been shared on third-party websites may still continue to be
              available as we do not have control over these websites.Your
              Personal Information may also appear in online searches. Other
              Personal Information that You have shared with others, or that
              other users have copied, may also remain visible. You should only
              share Personal Information with people that You trust because they
              will be able to save it or re- share it with others, including
              when they sync the Personal Information to a device.
            </p>
            <br />
            <p>
              <b>Rectification of Your information</b>: You may review, correct,
              update and change the information that You have provided to Us, at
              any point by making changes on the mobile application Homepage
              {"->Settings Icon ->"} Profile. Should You choose to update Your
              Personal Information or modify it in a way that is not verifiable
              by Us, or leads to such information being incorrect, We will be
              unable to provide You with access to the Platform or the Services.
              We reserve the right to verify and authenticate Your identity and
              Your Personal Information in order to ensure that We are able to
              offer You Services and/or make available the Platform. We can only
              keep Your Personal Information up-to-date and accurate to the
              extent You provide us with the necessary information. It is Your
              responsibility to notify Us of any changes in Your Personal
              Information. Access to or correction, updating or deletion of Your
              Personal Information may be denied or limited by Us if it would
              violate another person’s rights and/or is not otherwise permitted
              by applicable law.
            </p>
            <br />
            <p>
              <b>Changes to Our Privacy Policy</b>: We reserve the unconditional
              right to change, modify, add, or remove portions of this Privacy
              Policy at any time, and shall provide a notice to You of such
              changes. Any changes or updates will be eective immediately. You
              should review this Privacy Policy regularly for changes. You can
              determine if changes have been made by checking the “Last Updated”
              legend above. Your acceptance of the amended Privacy Policy by
              continuing to visit the Platform or using theServices, shall
              signify Your consent to such changes and agreement to be legally
              bound by the same
            </p>
            <br />
            <p>9) Grievance Officer:</p>
            <br />
            <p>
              We have appointed a grievance officer, under authorization, in
              accordance with the Information Technology Act, 2000 and the rules
              made thereunder, for the purpose of redressing any grievances or
              complaints you may have regarding the handling of Your Personal
              Information. You can contact the designated Grievance Officer for
              the purpose of this Privacy Policy, namely, Panduranga Acharya, at
              legal@Fresly.in. For order related concerns, reach out to
              support@fresly.in
            </p>
            <br />
            <p>
              10) <b>Questions?</b>
            </p>
            <br />
            <p>
              Please feel free to contact at this legal@Fresly.in regarding any
              questions on the Privacy Policy. We will use reasonable eorts to
              respond promptly to requests, questions or concerns You may have
              regarding Our use of Your Personal Information. Except where
              required by law, We cannot ensure a response to questions or
              comments regarding topics unrelated to thisPrivacy Policy or the
              privacy practices specified herein.
            </p>
            <br />
            <p>
              Further, please note that the Platform stores Your data with the
              cloud platform of Amazon Web Services provided by Amazon Web
              Services, Inc., which may store this data on their servers located
              outside of India.
            </p>
            <br />
            <p>
              Amazon Web Services has security measures in place to protect the
              loss, misuse and alteration of the information, details of which
              are available at https://aws.amazon.com/. The privacy policy
              adopted by Amazon Web Services is detailed
              inhttps://aws.amazon.com/privacy. In the event You have questions
              or concerns about the security measures adopted by Amazon Web
              Services, You can contact their data protection / privacy team /
              legal team or designated the grievance officer at these
              organisations, whose contact details are available in its privacy
              policy, or can also write to Our Grievance Officer at the address
              provided above.
            </p>
            <br />
            <p>© 2024 Fresly, All rights reserved</p>
          </div>
        ) : (
          <div className="mt-5">
            <p>
              At Fresly, we take the security of our systems seriously, and it
              is our constant endeavor to make our website a safe place for our
              customers to browse. However, in the rare case when some security
              researcher or member of the general public identifies a
              vulnerability in our systems, and responsibly shares the details
              of it with us, we appreciate their contribution, work closely with
              them to address such vulnerabilities with urgency, and if they
              want, publicly acknowledge their contribution. Fresly reserves all
              the rights to validate the reports to be valid or not on the basis
              of impact of vulnerability.
            </p>
            <br />
            <p>
              <b>To be eligible for recognition, you must</b>
            </p>
            <p>Be the first person to responsibly disclose the bug.</p>
            <p>
              Report a bug that could compromise our users' private data,
              circumvent the system's protections, or enable access to a system
              within our infrastructure.
            </p>
            <p>
              <b>Types of Recognition</b>
            </p>
            <p>
              <a href="">
                Hall Of Fame
              </a>
            </p>
            <p>
              <b>Rules of Engagement</b>
            </p>
            <p>
              You give us reasonable time to investigate and mitigate a
              vulnerability that you report.
            </p>
            <br />
            <p>
              Please refrain from accessing sensitive information (by using a
              test account and/or system), performing actions that may
              negatively affect other Fresly users (denial of service), or
              sending reports from automated tools.
            </p>
            <br />
            <p>
              You do not exploit a security vulnerability that you discover for
              any reason. (This includes demonstrating additional risk, such as
              attempted compromise of sensitive company data or probing for
              additional issues.)
            </p>
            <br />
            <p>
              Violating any laws or breaching any agreements in order to
              discover vulnerabilities.
            </p>
            <br />
            <p>
              You do not publicly disclose details of a security vulnerability
              that you've reported without Fresly's permission.
            </p>
            <p>
              <b>Programme terms</b>
            </p>
            <p>
              We recognise security researchers who help us to keep users safe
              by reporting vulnerabilities in our services. Recognition for such
              reports are entirely at Fresly’s discretion, based on risk, impact
              and other factors. For recognition in Fresly’s Hall of Fame, you
              first need to meet the following requirements:
            </p>
            <p>Adhere to our Responsible Disclosure Policy</p>
            <p>
              Report a security bug: identify a vulnerability in our services or
              infrastructure which creates a security or privacy risk. (Note
              that Fresly ultimately determines the risk of an vulnerability,
              and that many software bugs are not security vulnerabilities.)
              Your report must describe a problem involving one of the products
              or services listed under "Scope". We specifically exclude certain
              types of potential security vulnerabilities; these are listed
              under "Exclusions”. If you inadvertently cause a privacy violation
              or disruption (such as accessing account data, service
              configurations or other confidential information) while
              investigating an vulnerability, make sure that you disclose this
              in your report. In turn, we will follow these guidelines when
              evaluating reports under our responsible disclosure programme: We
              investigate and respond to all valid reports. Due to the volume of
              reports that we receive, however, we prioritise evaluations based
              on risk and other factors, and it may take some time before you
              receive a reply. We determine recognition in hall of fame based on
              a variety of factors, including (but not limited to) impact, ease
              of exploitation and quality of the report. Note that extremely
              low-risk vulnerabilities may not qualify for hall of fame at all.
              In the event of duplicate reports, we give recognition to the
              first person to submit a vulnerability. (Fresly determines
              duplicates and may not share details on the other reports.) Note
              that your use of Fresly services including for the purposes of
              this programme, is subject to Fresly’s Terms and Policies. We may
              retain any communications about security vulnerabilities that you
              report for as long as we deem necessary for programme purposes,
              and we may cancel or modify this programme at any time.
            </p>
            <p>
              <b>Scope</b>
            </p>
            <p>
              Android Fresly iOS Fresly Android Delivery Partner Fresly iOS
              Business Fresly
            </p>
            <p>
              <b>How to Report a Vulnerability?</b>
            </p>
            <p>
              If you happen to have identified a vulnerability on any of our web
              or mobile app properties, we request you to follow the steps
              outlined below: Please submit the vulnerability report form with
              the necessary details to recreate the vulnerability scenario. This
              may include screenshots, videos or simple text instructions.
            </p>
            <p>
              If possible, share with us your contact details (phone number), so
              that our security team can reach out to you if further inputs are
              needed to identify or close the problem.
            </p>
            <p>
              If the identified vulnerability can be used to potentially extract
              information of our customers or systems, or impair our system’s
              ability to function normally, then please refrain from actually
              exploiting such a vulnerability. This is absolutely necessary for
              us to consider your disclosure a responsible one. While we
              appreciate the inputs of Whitehat hackers, we may take legal
              recourse if the identified vulnerabilities are exploited for
              unlawful gains or getting access to restricted customer or system
              information or impairing our systems.
            </p>
            <p>
              <b>Report a Vulnerability</b>
            </p>
            <p>Send an email to security@Fresly</p>
            <p>
              <b>Qualifying Vulnerabilities</b>
            </p>
            <p>
              Any design or implementation issue that is reproducible and
              substantially affects the security of Fresly users is likely to be
              in scope for the program. Common examples include:
            </p>
            <p>Injections</p>
            <p>Cross Site Scripting (XSS)</p>
            <p>Cross Site Request Forgery (CSRF)</p>
            <p>Remote Code Execution (RCE)</p>
            <p>Authentication/Authorisation flaws</p>
            <p>Domain take-over vulnerabilities</p>
            <p>
              Able to take-over other Fresly user accounts (while testing, use
              your own another test account to validate)
            </p>
            <p>
              Any vulnerability that can a ect the Fresly Brand, user data and
              financial transactions
            </p>
            <p>
              <b>Exclusions</b>
            </p>
            <p>The following bugs are unlikely to be eligible:</p>
            <p>Vulnerabilities found through automated testing</p>
            <p>"Scanner output" or scanner-generated reports</p>
            <p>
              Publicly released CVE’s or 0-days in internet software within 90
              days of their disclosure
            </p>
            <p>
              "Advisory" or "Informational" reports that do not include any
              Fresly testing or context
            </p>
            <p>
              Vulnerabilities requiring MITM or physical access to the victim’s
              unlocked device. Denial of Service attacks
            </p>
            <p>SPF, DMARC and DKIM issues</p>
            <p>Content injection</p>
            <p>Hyperlink injection in emails</p>
            <p>IDN homograph attacks</p>
            <p>RTL Ambiguity</p>
            <p>Content Spoofing</p>
            <p>Vulnerabilities relating to Password Policy</p>
            <p>Full-Path Disclosure on any property</p>
            <p>Version number information disclosure</p>
            <p>
              Third-party applications on the Fresly Application directory
              (identified by the existence of a "Report this app" link on the
              app's page). Please report vulnerabilities with these services to
              the creator of that specific application.
            </p>
            <p>
              Clickjacking on pre-authenticated pages, or the non-existence of
              X-Frame-Options, or other non-exploitable clickjacking
              vulnerabilities
            </p>
            <p>
              CSRF-able actions that do not require authentication (or a
              session) to exploit Reports related to the following
              security-related headers
            </p>
            <p>Strict Transport Security (HSTS)</p>
            <p>XSS mitigation headers (X-Content-Type and X-XSS-Protection)</p>
            <p>X-Content-Type-Options</p>
            <p>
              Content Security Policy (CSP) settings (excluding nosniff in an
              exploitable scenario) Bugs that do not represent any security risk
            </p>
            <p>
              Security bugs in third-party applications or services built on the
              Fresly API - please report them to the third party that built the
              application or service
            </p>
            <p>
              Security bugs in software related to an acquisition for a period
              of 90 days following any public announcement
            </p>
            <p>HTTP TRACE or OPTIONS methods enabled</p>
            <p>
              Non-sensitive (i.e., non-session) cookies missing the Secure or
              HttpOnly flags Tap jacking
            </p>
            <p>
              Mobile client issues require a rooted device and/or outdated OS
              version or SSL pinning issues.
            </p>
            <p>Subdomain takeovers without supporting evidence</p>
            <p>Missing best practices in SSL/TLS configuration</p>
            <p>
              The Vulnerabilities that cannot be used to exploit other users or
              Fresly -- e.g., self- XSS or having a user paste JavaScript into
              the browser console
            </p>
            <p>
              Open ports without an accompanying proof-of-concept demonstrating
              vulnerability
            </p>
            <p>Vulnerabilities in the whitehat report form</p>
            <p>Submitting complaints about services</p>
            <p>
              Making fraud reports and/or suspicions of fraud reports from false
              mail or phishing e-mails
            </p>
            <p>Reporting viruses.</p>
            <p>
              Submitting complaints or questions about the availability of the
              website.
            </p>
            <p>
              <b>Acknowledgements</b>
            </p>
            <p>
              We do not have a bounty/cash reward program for such disclosures,
              but we express our gratitude for your contribution in different
              ways. For genuine ethical disclosures, we would be glad to
              publicly acknowledge your contribution in this section on our
              website. Of course, this will be done if you want a public
              acknowledgement.
            </p>
          </div>
        )}
      </div>
      <div className="w-full flex justify-center items-center">
        <section className="flex justify-center items-center border mb-10 gap-10 px-5 py-3 rounded-full">
          <p
            className={`cursor-pointer text-slate-500 ${
              page === "Privacy Policy"
                ? "text-slate-800 font-semibold"
                : "text-slate-500"
            }`}
            onClick={() => setPage("Privacy Policy")}
          >
            Privacy Policy
          </p>
          <p
            className={`cursor-pointer text-slate-500 ${
              page === "Terms Of Service"
                ? "text-slate-800 font-semibold"
                : "text-slate-500"
            }`}
            onClick={() => setPage("Terms Of Service")}
          >
            Terms Of Service
          </p>
          <p
            className={`cursor-pointer text-slate-500 ${
              page === "Resposible Discloser Policy"
                ? "text-slate-800 font-semibold"
                : "text-slate-500"
            }`}
            onClick={() => setPage("Resposible Discloser Policy")}
          >
            Resposible Discloser Policy
          </p>
        </section>
      </div>
    </div>
  );
}

export default Legal;
