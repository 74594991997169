import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Box,
  Chip,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import PopOverBox from "../../component/PopoverBox";
import PaginationBar from "../../component/PaginationBar";
import RightDrawer from "../../component/RightDrawer";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";

function STUsers() {
  // navigate to home if staff is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [productData, setProductData] = useState([]);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [enableAddCoinForm, setEnableAddCoinForm] = useState(false);
  const [coinAddConfirm, setCoinAddConfirm] = useState(false);
  const [enableConfirmMsg, setEnableConfirmMsg] = useState(false);
  const [coinAddValue, setCoinAddValue] = useState(0);
  const [notTitle, setNotTitle] = useState("");
  const [notBody, setNotBody] = useState("");

  // get products
  async function getProducts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/user/get/all/user/staff/${page}?search=${search}`
      );
      setProductData(res.data?.data?.data);
      setTotalPages(res.data?.data?.totalPages);
      console.log(res.data?.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function onSearch() {
    setReload((prev) => !prev);
  }

  function onReload() {
    setSearch("");
    setReload((prev) => !prev);
  }

  async function addCoin(userId) {
    console.log(userId);
    console.log(coinAddValue);
    if (coinAddValue <= 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Coin must be more than 0");
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/api/v1/wallet/coin/add`, {
        userId: userId,
        point: coinAddValue,
      });
      setReload((prev) => !prev);
      setCoinAddValue(0);
      setEnableAddCoinForm(false);
      setOpen(true);
      setSuccess(true);
      setMessage("Coin added successfully");
      setTimeout(() => {
        setEnableConfirmMsg(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Coin add failed");
    } finally {
      setLoading(false);
    }
  }

  async function sendSingleNot(fcmToken) {
    if (notBody === "" || notTitle === "" || fcmToken === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Title, body and fcm token is required");
      return null;
    }
    const data = {
      title: notTitle,
      body: notBody,
      fcmToken: fcmToken,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.post(
        `/api/v1/user/notification/send/single/user`,
        data
      );
      setOpen(true);
      setSuccess(true);
      setMessage("Notification send successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Notification sent failed");
      console.log(error);
    } finally {
      setLoading(false);
      setNotTitle("");
      setNotBody("");
    }
  }

  async function sendBulkNot() {
    if (notBody === "" || notTitle === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Title, body is required");
      return null;
    }
    const data = {
      title: notTitle,
      body: notBody,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.post(
        `/api/v1/user/notification/send/all`,
        data
      );
      setOpen(true);
      setSuccess(true);
      setMessage("Notification send successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Notification sent failed");
      console.log(error);
    } finally {
      setLoading(false);
      setNotTitle("");
      setNotBody("");
    }
  }

  useEffect(() => {
    getProducts();
  }, [reload, page]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Users</Heading>
          <div className="w-full mt-5">
            {productData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full flex justify-end gap-3 mt-[2rem]">
                  <ButtonCompo
                    variant={"outline"}
                    startIcon={<IoMdRefresh />}
                    onClick={onReload}
                  >
                    Refresh
                  </ButtonCompo>
                  <DialogBox
                    trigger={
                      <ButtonCompo variant={"primary"}>send offer</ButtonCompo>
                    }
                    title="Send Offer Through Notification"
                    actionButton={
                      <ButtonCompo onClick={sendBulkNot}>Send</ButtonCompo>
                    }
                  >
                    <div className="w-full space-y-3">
                      <InputFieldsWithIcon
                        label="Title"
                        onChange={(event) => setNotTitle(event?.target?.value)}
                      />
                      <InputFieldsWithIcon
                        label="Body"
                        onChange={(event) => setNotBody(event?.target?.value)}
                        multiline
                      />
                    </div>
                  </DialogBox>
                  <section className="flex gap-3">
                    <TextField
                      size="small"
                      placeholder="Search by phone number"
                      onChange={(event) => setSearch(event?.target?.value)}
                    />
                    <ButtonCompo variant={"primary"} onClick={onSearch}>
                      Search
                    </ButtonCompo>
                  </section>
                </div>
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Name
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Total Payment
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Email ID
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Phone Number
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Gender
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Country
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Addresses
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Wallet
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Send Notification
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {productData?.map((j, i) => (
                        <TableRow>
                          <TableCell className="!text-center">
                            {i + 1}
                          </TableCell>
                          <TableCell className="!text-center !font-semibold">
                            {j?.fullName}
                          </TableCell>
                          <TableCell className="!text-center !font-semibold">
                            ₹{j?.totalPayment}
                          </TableCell>
                          <TableCell className="!text-center">
                            {j?.email || "NIL"}
                          </TableCell>
                          <TableCell className="!text-center !space-x-2">
                            {j?.phone}
                          </TableCell>
                          <TableCell className="!text-center">
                            {j?.gender ? j?.gender : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {j?.country ? j?.country : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox
                              trigger={<ButtonCompo>View</ButtonCompo>}
                              maxWidth="sm"
                            >
                              <div className="w-full">
                                {j?.address?.map((e, i) => (
                                  <section className="w-full bg-white px-5 py-5 rounded-[5px] shadow-md space-y-2">
                                    <div className="flex w-full justify-between">
                                      <section className="flex gap-[3rem]">
                                        <p className="font-semibold text-[16px]">
                                          {e?.fullName}
                                        </p>
                                        <p className="font-semibold text-[16px]">
                                          Phone : <span>{e?.phone}</span>
                                        </p>
                                      </section>
                                    </div>
                                    <section>
                                      <p className="font-semibold text-[15px]">
                                        House No/Flat No :{" "}
                                        <span>{e?.houseNo}</span>
                                      </p>
                                      <p className="font-semibold text-[15px]">
                                        Landmark : <span>{e?.landmark}</span>
                                      </p>
                                      <p className="font-semibold text-[15px]">
                                        Street : <span>{e?.street}</span>
                                      </p>
                                      <p className="font-semibold text-[15px]">
                                        Pincode : <span>{e?.pincode}</span>
                                      </p>
                                      <p className="font-semibold text-[15px]">
                                        City : <span>{e?.city}</span>
                                      </p>
                                      <p className="font-semibold text-[15px]">
                                        State : <span>{e?.state}</span>
                                      </p>
                                    </section>
                                  </section>
                                ))}
                              </div>
                            </DialogBox>
                          </TableCell>
                          <TableCell className="!text-center">
                            <RightDrawer
                              actionButton={<ButtonCompo>View</ButtonCompo>}
                              width="300"
                            >
                              <div className="w-[20rem] p-5">
                                <section className="flex justify-end items-end cursor-pointer">
                                  <p className="text-[15px] font-medium text-slate-500">
                                    Close
                                  </p>
                                </section>
                                <div
                                  className="w-full"
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <h2 className="text-center text-[20px] font-semibold">
                                    Wallet & Coins
                                  </h2>
                                  <section className="w-full space-y-3 mt-5">
                                    <section>
                                      <p className="font-semibold text-[16px]">
                                        Coins : <span>{j?.wallet?.point}</span>
                                      </p>
                                      <p className="font-semibold text-[16px]">
                                        Money :{" "}
                                        <span>Rs. {j?.wallet?.money}</span>
                                      </p>
                                    </section>
                                  </section>
                                  <section className="bg-slate-100 mt-3 p-3 rounded-[10px]">
                                    <p className="text-slate-500 font-[15px]">
                                      Last withdrawal coin -{" "}
                                      <span className="text-slate-700 font-medium">
                                        {j?.wallet?.withdrawalPoint}
                                      </span>
                                    </p>
                                    <p className="text-slate-500 font-[15px]">
                                      Last withdrawal money -{" "}
                                      <span className="text-slate-700 font-medium">
                                        {j?.wallet?.withdrawalMoney}
                                      </span>
                                    </p>
                                  </section>
                                  <section className="flex justify-end items-center mt-3">
                                    <ButtonCompo
                                      variant={"primary"}
                                      onClick={(eve) => {
                                        eve?.stopPropagation();
                                        setEnableAddCoinForm(true);
                                      }}
                                    >
                                      Add Coins
                                    </ButtonCompo>
                                  </section>
                                  {enableAddCoinForm && (
                                    <section
                                      onClick={(event) =>
                                        event.stopPropagation()
                                      }
                                    >
                                      <InputFieldsWithIcon
                                        label="Coins"
                                        placeholder="E.g, 2, 3 etc"
                                        value={coinAddValue}
                                        onChange={(val) =>
                                          setCoinAddValue(val?.target?.value)
                                        }
                                      />
                                      <section className="flex justify-end mt-3">
                                        <ButtonCompo
                                          onClick={() => {
                                            setEnableAddCoinForm(false);
                                          }}
                                        >
                                          Back
                                        </ButtonCompo>
                                        <ButtonCompo
                                          onClick={() => {
                                            setEnableAddCoinForm(false);
                                            setEnableConfirmMsg(true);
                                          }}
                                        >
                                          Add
                                        </ButtonCompo>
                                      </section>
                                    </section>
                                  )}
                                  {enableConfirmMsg && (
                                    <section className="mt-5">
                                      <p>
                                        You can't edit the coin after saving it.
                                        Are you want to add it?
                                      </p>
                                      <section className="flex justify-end mt-3">
                                        <ButtonCompo
                                          onClick={() => {
                                            setEnableAddCoinForm(true);
                                            setEnableConfirmMsg(false);
                                          }}
                                        >
                                          Back
                                        </ButtonCompo>
                                        <ButtonCompo
                                          onClick={() => addCoin(j?._id)}
                                        >
                                          Add
                                        </ButtonCompo>
                                      </section>
                                    </section>
                                  )}
                                </div>
                              </div>
                            </RightDrawer>
                          </TableCell>
                          <TableCell className="!text-center">
                            {j?.fcmToken && j?.fcmToken?.length > 0 ? (
                              <DialogBox
                                trigger={<ButtonCompo>send</ButtonCompo>}
                                title="Send Notification"
                                actionButton={
                                  <ButtonCompo
                                    onClick={() => sendSingleNot(j?.fcmToken)}
                                  >
                                    Send
                                  </ButtonCompo>
                                }
                              >
                                <div className="w-full space-y-3">
                                  <InputFieldsWithIcon
                                    label="Title"
                                    onChange={(event) =>
                                      setNotTitle(event?.target?.value)
                                    }
                                  />
                                  <InputFieldsWithIcon
                                    label="Body"
                                    onChange={(event) =>
                                      setNotBody(event?.target?.value)
                                    }
                                    multiline
                                  />
                                </div>
                              </DialogBox>
                            ) : (
                              <p className="!font-semibold text-slate-500 text-[16px] !py-2">
                                SEND
                              </p>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div>
                    <PaginationBar
                      totalTablePage={totalPages}
                      setTablePage={setPage}
                      tablePage={page}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* ; */
}

export default STUsers;
