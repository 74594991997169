import React from "react";
import logo from "../../assets/companyLogo.png";
import { FaMoneyBill1 } from "react-icons/fa6";
import DrawerCompo from "../DrawerCompo";
import { MdDashboard } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { BsFillInboxesFill } from "react-icons/bs";
import { RiCoupon3Fill } from "react-icons/ri";
import { FaGift } from "react-icons/fa6";
import { FaImage } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { MdContactMail } from "react-icons/md";
import { FaTags } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { BsFillPiggyBankFill } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { Link } from "react-router-dom";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaWrench } from "react-icons/fa";

function STHeader() {
  const dataArray = [
    {
      heading: "Home",
      content: [
        {
          title: <p className="text-orange-500/90 font-bold">Dashboard</p>,
          icon: <MdDashboard size={"22px"} className="text-orange-400" />,
          link: "/admin/dashboard",
        },
      ],
    },
    {
      heading: "products",
      content: [
        {
          title: "Products",
          icon: <AiFillProduct size={"22px"} className="text-slate-800/60" />,
          link: "/admin/products",
        },
        {
          title: "Add Product",
          icon: <AiFillProduct size={"22px"} className="text-slate-800/60" />,
          link: "/admin/product/add",
        },
        {
          title: "Keywards",
          icon: <FaTags size={"22px"} className="text-slate-800/60" />,
          link: "/admin/keywards",
        },
        {
          title: "Catagories",
          icon: (
            <BsFillInboxesFill size={"22px"} className="text-slate-800/60" />
          ),
          link: "/admin/catagories",
        },
      ],
    },
    {
      heading: "orders",
      content: [
        {
          title: "New Orders",
          icon: <FaGift size={"22px"} className="text-slate-800/60" />,
          link: "/admin/orders/new",
        },
        {
          title: "Order History",
          icon: <FaGift size={"22px"} className="text-slate-800/60" />,
          link: "/admin/orders",
        },
        {
          title: "Referred Orders",
          icon: <FaGift size={"22px"} className="text-slate-800/60" />,
          link: "/admin/referred/orders",
        },
      ],
    },
    {
      heading: "manual orders",
      content: [
        {
          title: "New Manual Orders",
          icon: <FaGift size={"22px"} className="text-slate-800/60" />,
          link: "/admin/manual/orders/new",
        },
        {
          title: "Manual Order History",
          icon: <FaGift size={"22px"} className="text-slate-800/60" />,
          link: "/admin/manual/orders/history",
        },
      ],
    },
    {
      heading: "Wallet & Coins",
      content: [
        {
          title: "Withdrawal Requests",
          icon: (
            <BsFillPiggyBankFill size={"22px"} className="text-slate-800/60" />
          ),
          link: "/admin/withdrawal/requests",
        },
        {
          title: "Transanction History",
          icon: <FaMoneyBillWave size={"22px"} className="text-slate-800/60" />,
          link: "/admin/transanctions",
        },
      ],
    },
    {
      heading: "miscellaneous",
      content: [
        {
          title: "Coupons",
          icon: <RiCoupon3Fill size={"22px"} className="text-slate-800/60" />,
          link: "/admin/coupons",
        },
        {
          title: "Charges",
          icon: <FaMoneyBill1 size={"22px"} className="text-slate-800/60" />,
          link: "/admin/charges",
        },
        {
          title: "Banners",
          icon: <FaImage size={"22px"} className="text-slate-800/60" />,
          link: "/admin/banners",
        },
        {
          title: "Pincodes",
          icon: <FaLocationDot size={"22px"} className="text-slate-800/60" />,
          link: "/admin/pincodes",
        },
        {
          title: "Users",
          icon: <FaUsers size={"22px"} className="text-slate-800/60" />,
          link: "/admin/users",
        },
        {
          title: "Change Password",
          icon: <FaWrench size={"22px"} className="text-slate-800/60" />,
          link: "/admin/change/password",
        },
      ],
    },
  ];

  return (
    <header className="w-full px-5 py-3 fixed top-0 flex justify-between items-center bg-white z-[100]">
      <DrawerCompo dataArray={dataArray} heading={"Admin"} />
      <div className="flex gap-10">
        <Link className="flex items-center gap-3" to={"/admin/users"}>
          <FaUsers size={25} /> All Users
        </Link>
        <Link className="flex items-center gap-3" to={"/admin/charges"}>
          <FaMoneyBillWave size={25} /> Charges
        </Link>
        <Link className="flex items-center gap-3" to={"/admin/products"}>
          <FaGift size={25} /> All Products
        </Link>
        <Link
          className="flex items-center gap-3"
          to={
            "https://wa.me/9148054805/?text=Hi%20AppNest%20Support%20Team%0AWe%20are%20from%20fresly%0AWe%20are%20facing%20issue%20on%20Fresly%20project%0A%0AThank%20You%0AFresly%20Team"
          }
        >
          <RiCustomerService2Fill size={25} /> Support
        </Link>
      </div>
      <Link to={"/admin/dashboard"}>
        <img src={logo} className="w-[3rem]" alt="" />
      </Link>
    </header>
  );
}

export default STHeader;
