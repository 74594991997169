import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaMoneyBill1 } from "react-icons/fa6";
import { MdContactMail, MdDashboard } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { BsFillInboxesFill } from "react-icons/bs";
import { RiCoupon3Fill } from "react-icons/ri";
import { FaGift } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import logo from "../../assets/companyLogo.png";
import { axiosInstance } from "../../constant";
import ButtonCompo from "../../component/ButtonCompo";

function STDashboard() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  // const dataArray = [
  //   {
  //     title: "Products",
  //     icon: (
  //       <AiFillProduct
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/products",
  //   },
  //   {
  //     title: "Add Product",
  //     icon: (
  //       <AiFillProduct
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/product/add",
  //   },
  //   {
  //     title: "Sections & Categories",
  //     icon: (
  //       <BsFillInboxesFill
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/catagories",
  //   },
  //   {
  //     title: "New Orders",
  //     icon: (
  //       <FaGift
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/orders/new",
  //   },
  //   {
  //     title: "Order History",
  //     icon: (
  //       <FaGift
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/orders",
  //   },
  //   {
  //     title: "Coupons",
  //     icon: (
  //       <RiCoupon3Fill
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/coupons",
  //   },
  //   {
  //     title: "Charges",
  //     icon: (
  //       <FaMoneyBill1
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/charges",
  //   },
  //   {
  //     title: "Users",
  //     icon: (
  //       <FaUsers
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/users",
  //   },
  //   {
  //     title: "Inquiry",
  //     icon: (
  //       <MdContactMail
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "/admin/contact",
  //   },
  //   {
  //     title: "IT Support",
  //     icon: (
  //       <RiCustomerService2Fill
  //         size={"70px"}
  //         className="text-orange-400 hover:text-orange-600"
  //       />
  //     ),
  //     link: "https://wa.me/9148054805/?text=Hi%20AppNest%0AWe%20are%20from%20NBS%0AWe%20are%20facing%20issue%20on%20website",
  //   },
  // ];

  // --------------------------------------------------------------------------------
  // ------------------------------Main Code-----------------------------------------
  // --------------------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const [orderData, setOrderData] = useState([]);

  // get dashboard data
  async function getData() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/dashboard/get/data/all`);
      console.log(res.data?.data);
      setAllData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get orders
  async function getOrders() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/payment/get/all/orders/staff/1?keywords=Confirmed`
      );
      const rawData = res.data?.data;
      console.log(res.data?.data?.data);
      setOrderData(rawData?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
    getOrders();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <img src={logo} className="w-[10rem]" alt="" />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <h1 className="mt-[2rem] heading_text text-[35px] font-bold">
        Admin Dashboard
      </h1>
      <div className="w-[90vw] xl:w-[70vw] mt-[3rem]">
        <div className="grid grid-cols-3 gap-[3rem] place-items-center text-center">
          <Link to={"/admin/orders/new"}>
            <div className="border w-[12rem] lg:w-[20rem] py-3 bg-green-700 rounded-[25px]">
              <p className="text-white text-[20px]">Today Order</p>
              <p className="text-white text-[35px] font-bold">
                {allData?.todayOrder || 0}
              </p>
            </div>
          </Link>
          <Link to={"/admin/catagories"}>
            <div className="border w-[12rem] lg:w-[20rem] py-3 bg-green-700 rounded-[25px]">
              <p className="text-white text-[20px]">Categories</p>
              <p className="text-white text-[35px] font-bold">
                {allData?.totalCategory || 0}
              </p>
            </div>
          </Link>
          <Link to={"/admin/manual/orders/new"}>
            <div className="border w-[12rem] lg:w-[20rem] py-3 bg-green-700 rounded-[25px]">
              <p className="text-white text-[20px]">Manual Order</p>
              <p className="text-white text-[35px] font-bold">
                {allData?.totalManualOrder || 0}
              </p>
            </div>
          </Link>
          <Link to={"/admin/products"}>
            <div className="border w-[12rem] lg:w-[20rem] py-3 bg-green-700 rounded-[25px]">
              <p className="text-white text-[20px]">All Products</p>
              <p className="text-white text-[35px] font-bold">
                {allData?.totalProduct || 0}
              </p>
            </div>
          </Link>
          <Link to={"/admin/referred/orders"}>
            <div className="border w-[12rem] lg:w-[20rem] py-3 bg-green-700 rounded-[25px]">
              <p className="text-white text-[20px]">Total Referrals</p>
              <p className="text-white text-[35px] font-bold">
                {allData?.totalReferredOrders || 0}
              </p>
            </div>
          </Link>
          <Link to={"/admin/coupons"}>
            <div className="border w-[12rem] lg:w-[20rem] py-3 bg-green-700 rounded-[25px]">
              <p className="text-white text-[20px]">Coupons</p>
              <p className="text-white text-[35px] font-bold">
                {allData?.totalCoupons || 0}
              </p>
            </div>
          </Link>
        </div>
        <div className="mt-5">
          <h2 className="text-[25px] font-semibold">All Recent Orders</h2>
          <div className="mt-5">
            {orderData?.length === 0 ? (
              <div>
                <p className="text-center">No order found</p>
              </div>
            ) : (
              <div>
                {orderData?.map((order, i) => (
                  <section className="grid grid-cols-6 place-items-center cursor-pointer hover:bg-slate-200 py-3 rounded-[10px]">
                    <div className="text-center">{i + 1}</div>
                    <div className="text-center">
                      {order?.address?.fullName}
                    </div>
                    <div className="text-center">{order?.address?.phone}</div>
                    <div className="text-center">
                      {new Date(order?.dateAndTime).toLocaleString(
                        "en-IN"
                      ) === "Invalid Date"
                        ? new Date(order?.date).toLocaleDateString("en-IN")
                        : new Date(order?.dateAndTime).toLocaleString(
                            "en-IN"
                          )}
                    </div>
                    <div className="text-center">
                      Delivery in {order?.deliveryTime}
                    </div>
                    <Link to={`/admin/order/get/${order?._id}`}>
                      <ButtonCompo>More</ButtonCompo>
                    </Link>
                  </section>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default STDashboard;
