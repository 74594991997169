import axios from "axios";

const stringRegex = /^[a-zA-Z\s]+$/; // a-z & A-Z
const addressRegex = /^[a-zA-Z0-9\s\-,]+$/; // 1-9 & - and , & a-z & A-Z
const numberRegex = /^[0-9]+$/ // 1-9
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
// const SERVER_URL = "http://127.0.0.1:7000"
const SERVER_URL = "https://api.fresly.in"

const axiosInstance = axios.create({
    baseURL: SERVER_URL,
    withCredentials: true
})

export {
    stringRegex,
    addressRegex,
    numberRegex,
    passwordRegex,
    SERVER_URL,
    axiosInstance
}