import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import SelectField from "../../component/SelectField";
import ButtonCompo from "../../component/ButtonCompo";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import DialogBox from "../../component/DialogBox";

function STSingleOrder() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState({});
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [notFOund, setNotfound] = useState(false);
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState("");
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [reload, setReload] = useState(false);
  const [razorpayPaymentDetails, setRazorpayPaymentDetails] = useState({});
  const alertRef = useRef(null);

  const handleAlertOpen = () => {
    if (alertRef.current) {
      alertRef.current.handleOpen();
    }
  };

  // get order
  async function getOrder() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/payment/get/single/order/staff/${orderId}`
      );
      const rawData = res.data?.data;
      console.log(rawData);
      if (rawData?.length === 0) {
        setNotfound(true);
      }
      setOrderData(rawData[0]);
      setSelectedDeliveryStatus(rawData[0]?.delivaryStatus);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // change status
  async function changeStatus() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/payment/change/delivary/status/${orderId}/${selectedDeliveryStatus}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Status has been changed");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // payment re-verify
  async function reverifyPayment() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/payment/check/payment/${orderData?.order_id}`
      );
      setRazorpayPaymentDetails(res.data?.data);
      handleAlertOpen();
      setReload((prev) => !prev);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Order ID does not exist");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // delivery status
  const deliveryStatusArray = [
    {
      title: "Order Confirmed",
      value: "Confirmed",
    },
    {
      title: "Order Prepeared",
      value: "Prepeared",
    },
    {
      title: "Order Dispatched",
      value: "Dispatched",
    },
    {
      title: "Order Out for Delivary",
      value: "Out for Delivary",
    },
    {
      title: "Order Delivered",
      value: "Delivered",
    },
    {
      title: "Order Canceled",
      value: "Canceled",
    },
  ];

  useEffect(() => {
    getOrder();
  }, [reload]);

  console.log(orderData);

  if (notFOund) {
    return (
      <div>
        <NotFound />
      </div>
    );
  }

  return (
    <>
      {loading && <Loading />}
      <DialogBox ref={alertRef} closeActionButton={"okay"}>
        <div className="w-full space-y-1">
          {Object.keys(razorpayPaymentDetails).map((key, i) => (
            <p>
              {key} :{" "}
              <span className="text-slate-500">
                {key === "amount_due" ||
                key === "amount" ||
                key === "amount_paid"
                  ? Number(razorpayPaymentDetails[key]) / 100
                  : razorpayPaymentDetails[key]}
              </span>
            </p>
          ))}
        </div>
      </DialogBox>
      <div className="w-full flex justify-center items-center py-5">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[100vw] lg:w-[50rem] p-5 rounded-[5px] shadow-md bg-white">
          <section>
            <h1 className="text-[20px] font-medium">
              Order ID: {orderData?.orderNumber}{" "}
              <span className="bg-green-200/50 px-3 py-1 rounded-full text-[15px] text-green-800">
                Delivery within{" "}
                <span className="font-semibold">{orderData?.deliveryTime}</span>
              </span>
            </h1>
            <p className="mt-2 text-slate-500">
              {new Date(orderData?.dateAndTime).toLocaleString("en-IN") ===
              "Invalid Date"
                ? new Date(orderData?.date).toLocaleDateString("en-IN")
                : new Date(orderData?.dateAndTime).toLocaleString("en-IN")}
            </p>
          </section>
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">Ordered Items</p>
            <div className="mt-5 space-y-3">
              {orderData?.products?.map((product, i) => (
                <section className="flex justify-start items-start gap-5">
                  <section className="w-[7rem] h-[7rem] rounded-[10px] bg-slate-100">
                    <img
                      src={`${SERVER_URL}/api/v1/file/get/${product?.productImage}`}
                      className="w-full h-full object-cover"
                      alt=""
                    />
                  </section>
                  <section>
                    <p className="text-[15px] text-slate-500">
                      {product?.productCatagory}
                    </p>
                    <p className="font-medium">{`${product?.productTitle}, ${product?.productQuantity}`}</p>
                    <p className="text-[15px] text-slate-500">
                      Qty: <span>{product?.quantity}</span>
                    </p>
                    <p className="text-[19px] mt-3 font-medium">
                      ₹{product?.totalPrice}
                    </p>
                  </section>
                </section>
              ))}
            </div>
          </div>
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">Order Info</p>
            <div className="mt-5">
              <p className="flex justify-start items-center gap-3 font-medium">
                Delivary Status:{" "}
                <span className="text-slate-700">
                  <SelectField
                    value={selectedDeliveryStatus}
                    valueArray={deliveryStatusArray}
                    setValue={(event) => {
                      setSelectedDeliveryStatus(event?.target?.value);
                      setEnableSaveButton(true);
                    }}
                    label={"Change Status"}
                  />
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium mt-5">
                Payment Status:{" "}
                <span className="text-slate-700">
                  {orderData?.paymentStatus === "success" ? (
                    <span className="text-green-600 font-medium">Success</span>
                  ) : orderData?.paymentStatus === "Due" ? (
                    <span className="text-yellow-700 font-medium">
                      Awaiting
                    </span>
                  ) : (
                    <span className="text-red-600 font-medium">Failed</span>
                  )}
                </span>
              </p>
              {enableSaveButton && (
                <div className="w-full flex justify-end mt-3">
                  <ButtonCompo variant={"primary"} onClick={changeStatus}>
                    Save
                  </ButtonCompo>
                </div>
              )}
            </div>
          </div>
          {orderData?.invoice !== undefined &&
          orderData?.invoice?.length > 0 ? (
            <Link to={orderData?.invoice} target="_blank">
              <div className="mt-5 px-5 py-3 border rounded-[10px] cursor-pointer hover:bg-slate-100 flex justify-between items-center">
                <p>Download Invoice</p>
                <IoIosArrowForward size={"18px"} />
              </div>
            </Link>
          ) : (
            ""
          )}
          {/* <div className="mt-[1rem] border rounded-[10px] p-5">
            <div className="justify-between flex w-full items-center">
              <p className="font-medium">Payment Info</p>
              <ButtonCompo onClick={reverifyPayment}>
                Reverify Payment
              </ButtonCompo>
            </div>
            <div className="mt-5 space-y-2">
              <p className="flex justify-start items-center gap-3 font-medium">
                Razorpay Payment ID:{" "}
                <span className="text-slate-700">
                  {orderData?.razorpayPaymentId}
                </span>
              </p>
              <p className="flex justify-start items-center gap-3 font-medium">
                Razorpay Order ID:{" "}
                <span className="text-slate-700">
                  {orderData?.razorpayOrderId}
                </span>
              </p>
              <p className="flex justify-start items-center gap-3 font-medium">
                Signature:{" "}
                <span className="text-slate-700">
                  {orderData?.razorpaySignature}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Payment Status:{" "}
                <span className="text-slate-700">
                  {orderData?.paymentStatus === "success" ? (
                    <span className="text-green-600 font-medium">Success</span>
                  ) : orderData?.paymentStatus === "Due" ? (
                    <span className="text-yellow-700 font-medium">
                      Awaiting
                    </span>
                  ) : (
                    <span className="text-red-600 font-medium">Failed</span>
                  )}
                </span>
              </p>
            </div>
          </div> */}
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">Shipping Address</p>
            <div className="mt-5">
              <p className="flex justify-start gap-3 font-medium">
                Name:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.fullName}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Phone Number:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.phone}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                House No/Flat No:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.houseNo}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Landmark:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.landmark}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Street Address:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.street}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Pincode:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.pincode}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                City:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.city}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                State:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.state}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">User Details</p>
            <div className="mt-5">
              <p className="flex justify-start gap-3 font-medium">
                Name:{" "}
                <span className="text-slate-700">
                  {orderData?.user?.fullName}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Phone Number:{" "}
                <span className="text-slate-700">{orderData?.user?.phone}</span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Email:{" "}
                <span className="text-slate-700">{orderData?.user?.email}</span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Customer ID:{" "}
                <span className="text-slate-700">
                  {orderData?.user?.customerId}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Country:{" "}
                <span className="text-slate-700">
                  {orderData?.user?.country}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">Order Summery</p>
            <div className="mt-5 overflow-auto">
              <Table>
                <TableHead>
                  <TableRow className="bg-slate-100">
                    <TableCell className="!text-center">Sl No.</TableCell>
                    <TableCell className="!text-left">Item</TableCell>
                    <TableCell className="!text-center">Quantity</TableCell>
                    <TableCell className="!text-center">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderData?.products?.map((product, i) => (
                    <TableRow>
                      <TableCell className="!text-center">{i + 1}</TableCell>
                      <TableCell className="!text-left">{`${product?.productTitle}, ${product?.productQuantity}`}</TableCell>
                      <TableCell className="!text-center">
                        {product?.quantity}
                      </TableCell>
                      <TableCell className="!text-center">
                        ₹
                        {Number(product?.totalPrice) *
                          Number(product?.quantity)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {orderData?.discount && (
                    <TableRow>
                      <TableCell className="!text-center" colSpan={1}>
                        Total Discount
                      </TableCell>
                      <TableCell className="!text-left" colSpan={2}></TableCell>
                      <TableCell className="!text-center">
                        -₹{orderData?.discount}
                      </TableCell>
                    </TableRow>
                  )}
                  {orderData?.referDiscount > 0 && (
                    <TableRow>
                      <TableCell className="!text-center" colSpan={1}>
                        Refer Discount ({orderData?.referCode})
                      </TableCell>
                      <TableCell className="!text-left" colSpan={2}></TableCell>
                      <TableCell className="!text-center">
                        -₹{orderData?.referDiscount}
                      </TableCell>
                    </TableRow>
                  )}
                  {orderData?.charges &&
                    orderData?.charges?.map((charge, i) => (
                      <TableRow>
                        <TableCell className="!text-center" colSpan={1}>
                          {charge?.title}
                        </TableCell>
                        <TableCell
                          className="!text-left"
                          colSpan={2}
                        ></TableCell>
                        <TableCell className="!text-center">
                          +₹{charge?.amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell className="!text-center" colSpan={1}>
                      Total
                    </TableCell>
                    <TableCell className="!text-left" colSpan={2}></TableCell>
                    <TableCell className="!text-center">
                      <span className="text-[20px] font-semibold text-green-600">
                        ₹{orderData?.totalPrice}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default STSingleOrder;
