import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import { MdCloudUpload } from "react-icons/md";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import PaginationBar from "../../component/PaginationBar";
import SelectField from "../../component/SelectField";

function STCatagory() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [catagoryData, setCatagoryData] = useState([]);
  const [catagoryName, setCatagoryName] = useState("");
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const [catReload, setCatReload] = useState(false);
  const [secReload, setSecReload] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [sectionArray, setSectionArray] = useState([]);
  const [reload, setReload] = useState(false);
  const [isActive, setIsActive] = useState(true);

  // get sections
  async function getSection() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/product/section/get/all`);
      console.log(res.data?.data);
      const rawData = res.data?.data;
      let secArray = [];
      rawData?.map((section) => {
        const obj = {
          title: section?.name,
          value: section?._id,
        };
        secArray.push(obj);
      });
      setSectionArray(secArray);
      setSectionData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get catagory
  async function getCatagories() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/catagory/list?isGetAll=true`
      );
      console.log(res.data.data);
      setCatagoryData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add section
  async function addSection() {
    if (sectionName === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Name is required");
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/product/section/add/${sectionName}`
      );
      setSecReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Section added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // add section
  async function editSection(sectionId) {
    if (sectionName === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Name is required");
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.put(`/api/v1/product/section/edit`, {
        name: sectionName,
        sectionId: sectionId,
      });
      setSecReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Section updated successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // delete section
  async function deleteSection(sectionId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/product/section/delete/${sectionId}`
      );
      setSecReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Section deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // add catagory
  async function addCatagory() {
    if (catagoryName === "" || file === null || sectionId === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Name, image and section is required");
      return null;
    }
    const formData = new FormData();
    formData.append("name", catagoryName);
    formData.append("catagoryImg", file);
    formData.append("sectionId", sectionId);
    try {
      setLoading(true);
      const res = await axiosInstance.post(
        "/api/v1/product/catagory/add",
        formData
      );
      setCatReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Catagory added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // update catagory
  async function updateCatagory(catagoryId) {
    const formData = new FormData();
    formData.append("catagoryId", catagoryId);
    formData.append("sectionId", sectionId);
    formData.append("status", isActive);
    formData.append("name", catagoryName);
    if (file !== null) formData.append("catagoryImg", file);
    try {
      setLoading(true);
      const res = await axiosInstance.put(
        "/api/v1/product/catagory/edit",
        formData
      );
      setCatReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Catagory updated successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // delete catagory
  async function deleteCatagory(catagoryId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/product/catagory/delete/${catagoryId}`
      );
      setCatReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Catagory deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // set category value
  function setValue(data) {
    setCatagoryName(data?.name);
    setSectionId(data?.sectionId);
    setIsActive(data?.isActive);
  }

  // set section value
  function setSecValue(data) {
    setSectionName(data?.name);
  }

  const isActiveArray = [
    {
      title: "Active",
      value: true,
    },
    {
      title: "Inactive",
      value: false,
    },
  ];

  useEffect(() => {
    getCatagories();
  }, [catReload, reload]);

  useEffect(() => {
    getSection();
  }, [secReload, reload]);

  if (sectionData?.length === 0) {
    return (
      <>
        {loading && <Loading />}
        <div className="w-full flex justify-center items-center">
          {success !== undefined && (
            <AlertBox
              open={open}
              setOpen={setOpen}
              success={success}
              title={message}
            />
          )}
          <div className="w-[90vw]">
            <Heading>Sections & Categories</Heading>
            <div className="w-full mt-5">
              <NotFound width="15rem" />
              <div className="mt-7 flex justify-center">
                <DialogBox
                  trigger={
                    <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                      New Section
                    </ButtonCompo>
                  }
                  title="Add New Section"
                  actionButton={
                    <ButtonCompo onClick={addSection}>Save</ButtonCompo>
                  }
                >
                  <InputFieldsWithIcon
                    label="Section Name"
                    onChange={(event) => setSectionName(event?.target?.value)}
                  />
                </DialogBox>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Sections & Categories</Heading>
          <div className="w-full mt-5">
            <div className="w-full flex justify-end gap-3">
              <ButtonCompo
                variant={"outline"}
                startIcon={<IoMdRefresh />}
                onClick={() => setReload((prev) => !prev)}
              >
                Refresh
              </ButtonCompo>
              <DialogBox
                trigger={
                  <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                    New Section
                  </ButtonCompo>
                }
                title="Add New Section"
                actionButton={
                  <ButtonCompo onClick={addSection}>Save</ButtonCompo>
                }
              >
                <InputFieldsWithIcon
                  label="Section Name"
                  onChange={(event) => setSectionName(event?.target?.value)}
                />
              </DialogBox>
              <DialogBox
                trigger={
                  <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                    New Catagory
                  </ButtonCompo>
                }
                title="Add New Catagory"
                actionButton={
                  <ButtonCompo onClick={addCatagory}>Save</ButtonCompo>
                }
              >
                <div className="space-y-3">
                  <input
                    type="file"
                    className="hidden"
                    onChange={(event) => setFile(event?.target?.files[0])}
                    ref={fileRef}
                  />
                  <InputFieldsWithIcon
                    label="Catagory Name"
                    onChange={(event) => setCatagoryName(event?.target?.value)}
                    isImp={true}
                  />
                  <SelectField
                    isImp={true}
                    label={"Select Section"}
                    setValue={(event) => setSectionId(event?.target?.value)}
                    value={sectionId}
                    valueArray={sectionArray}
                  />
                  <section
                    onClick={() => fileRef?.current?.click()}
                    className="flex w-full justify-between items-center border-slate-300 border rounded-[5px] py-4 px-3 cursor-pointer hover:border-[#FF8000]"
                  >
                    <p className="text-slate-700 font-medium">
                      {file === null ? "Upload A Image" : "1 file selcted"}
                    </p>
                    <MdCloudUpload size={"25px"} className="text-[#FF8000]" />
                  </section>
                </div>
              </DialogBox>
            </div>
            <div className="w-full flex items-start gap-5 justify-center mt-5">
              <div className="w-[40rem]">
                <div className="text-[#FF8000] py-3 px-5 border border-[#ff800094] bg-white rounded-t-[10px] font-bold">
                  Sections
                </div>
                <Table>
                  <TableHead>
                    <TableRow className="bg-[#FF8000]">
                      <TableCell className="!text-center !text-white !font-semibold">
                        SL No
                      </TableCell>
                      <TableCell className="!text-center !text-white !font-semibold">
                        Name
                      </TableCell>
                      <TableCell className="!text-center !text-white !font-semibold">
                        Edit
                      </TableCell>
                      <TableCell className="!text-center !text-white !font-semibold">
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sectionData?.map((section, i) => (
                      <TableRow className="bg-white">
                        <TableCell className="!text-center">{i + 1}</TableCell>
                        <TableCell className="!text-center">
                          {section?.name}
                        </TableCell>
                        <TableCell className="!text-center">
                          <DialogBox
                            trigger={
                              <ButtonCompo onClick={() => setSecValue(section)}>
                                Edit
                              </ButtonCompo>
                            }
                            title="Edit Section"
                            actionButton={
                              <ButtonCompo
                                onClick={() => editSection(section?._id)}
                              >
                                Save
                              </ButtonCompo>
                            }
                          >
                            <InputFieldsWithIcon
                              label="Section Name"
                              onChange={(event) =>
                                setSectionName(event?.target?.value)
                              }
                              value={sectionName}
                            />
                          </DialogBox>
                        </TableCell>
                        <TableCell className="!text-center">
                          <ButtonCompo
                            variant={"destructive"}
                            onClick={() => deleteSection(section?._id)}
                          >
                            Delete
                          </ButtonCompo>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="w-full">
                <div className="text-[#FF8000] py-3 px-5 rounded-t-[10px] bg-white border border-[#ff800094] font-bold">
                  Catagories
                </div>
                <Table>
                  <TableHead>
                    <TableRow className="bg-[#FF8000]">
                      <TableCell className="!text-white !text-center !font-semibold">
                        Serial No.
                      </TableCell>
                      <TableCell className="!text-white !text-center !font-semibold">
                        Image
                      </TableCell>
                      <TableCell className="!text-white !text-center !font-semibold">
                        Catagory
                      </TableCell>
                      <TableCell className="!text-white !text-center !font-semibold">
                        Section
                      </TableCell>
                      <TableCell className="!text-white !text-center !font-semibold">
                        Active/Inactive
                      </TableCell>
                      <TableCell className="!text-white !text-center !font-semibold">
                        Subcategories
                      </TableCell>
                      <TableCell className="!text-white !text-center !font-semibold">
                        Edit
                      </TableCell>
                      <TableCell className="!text-white !text-center !font-semibold">
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="bg-white shadow-sm">
                    {catagoryData?.map((e, i) => (
                      <TableRow>
                        <TableCell className="!text-center !font-bold">
                          {Number(i) + 1}
                        </TableCell>
                        <TableCell className="!flex !justify-center !items-center">
                          <Link
                            className="w-full !flex !justify-center !items-center"
                            to={`${SERVER_URL}/api/v1/file/get/${e?.image}`}
                            target="_blank"
                          >
                            <section className="w-[3rem] h-[3rem]">
                              <img
                                src={`${SERVER_URL}/api/v1/file/get/${e?.image}`}
                                className="w-full h-full object-cover"
                                alt=""
                              />
                            </section>
                          </Link>
                        </TableCell>
                        <TableCell className="!text-center">
                          {e?.name}
                        </TableCell>
                        <TableCell className="!text-center">
                          {e?.section?.name || "Not found"}
                        </TableCell>
                        <TableCell className="!text-center">
                          {e?.isActive === true ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell className="!text-center">
                          <ButtonCompo
                            href={`/admin/subcategory/${e?._id}/${e?.name}`}
                          >
                            Subcategories
                          </ButtonCompo>
                        </TableCell>
                        <TableCell className="!text-center">
                          <DialogBox
                            trigger={
                              <ButtonCompo onClick={() => setValue(e)}>
                                Edit
                              </ButtonCompo>
                            }
                            title="Edit Catagory"
                            actionButton={
                              <ButtonCompo
                                onClick={() => updateCatagory(e?._id)}
                              >
                                Save
                              </ButtonCompo>
                            }
                          >
                            <div className="space-y-3">
                              <input
                                type="file"
                                className="hidden"
                                onChange={(event) =>
                                  setFile(event?.target?.files[0])
                                }
                                ref={fileRef}
                              />
                              <InputFieldsWithIcon
                                label="Catagory Name"
                                value={catagoryName}
                                onChange={(event) =>
                                  setCatagoryName(event?.target?.value)
                                }
                              />
                              <SelectField
                                isImp={true}
                                label={"Select Section"}
                                setValue={(event) =>
                                  setSectionId(event?.target?.value)
                                }
                                value={sectionId}
                                valueArray={sectionArray}
                              />
                              <SelectField
                                isImp={true}
                                label={"Select Section"}
                                setValue={(event) =>
                                  setIsActive(event?.target?.value)
                                }
                                value={isActive}
                                valueArray={isActiveArray}
                              />
                              <section
                                onClick={() => fileRef?.current?.click()}
                                className="flex w-full justify-between items-center border-slate-300 border rounded-[5px] mt-3 py-4 px-3 cursor-pointer hover:border-[#FF8000]"
                              >
                                <p className="text-slate-700 font-medium">
                                  {file === null
                                    ? "Upload A Image"
                                    : "1 file selcted"}
                                </p>
                                <MdCloudUpload
                                  size={"25px"}
                                  className="text-[#FF8000]"
                                />
                              </section>
                            </div>
                          </DialogBox>
                        </TableCell>
                        <TableCell className="!text-center">
                          <ButtonCompo
                            variant={"destructive"}
                            onClick={() => deleteCatagory(e?._id)}
                          >
                            Delete
                          </ButtonCompo>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default STCatagory;
