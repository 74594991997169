import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import { MdCloudUpload } from "react-icons/md";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import SelectField from "../../component/SelectField";

function STKeyward() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [reload, setReload] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [desc, setDesc] = useState("");

  // get keywards
  async function getSection() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/keyward/get/all`);
      console.log(res.data?.data);
      setSectionData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add keyward
  async function addSection() {
    if (sectionName === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Name is required");
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/api/v1/keyward/add`, {
        name: sectionName,
        desc: desc,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Keyward added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // edit keyward
  async function editSection(sectionId) {
    if (sectionName === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Name is required");
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.put(`/api/v1/keyward/edit`, {
        name: sectionName,
        keywardId: sectionId,
        isActive: isActive,
        desc: desc,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Keyward updated successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // delete keyward
  async function deleteSection(sectionId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/keyward/delete/${sectionId}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Keyward deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const isActiveArray = [
    {
      title: "Enable",
      value: true,
    },
    {
      title: "Disable",
      value: false,
    },
  ];

  // set section value
  function setSecValue(data) {
    setSectionName(data?.name);
    setIsActive(data?.isActive);
    setDesc(data?.desc);
  }

  useEffect(() => {
    getSection();
  }, [reload]);

  if (sectionData?.length === 0) {
    return (
      <>
        {loading && <Loading />}
        <div className="w-full flex justify-center items-center">
          {success !== undefined && (
            <AlertBox
              open={open}
              setOpen={setOpen}
              success={success}
              title={message}
            />
          )}
          <div className="w-[90vw]">
            <Heading>Keywards</Heading>
            <div className="w-full mt-5">
              <NotFound width="15rem" />
              <div className="mt-7 flex justify-center">
                <DialogBox
                  trigger={
                    <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                      New Keyward
                    </ButtonCompo>
                  }
                  title="Add New Keyward"
                  actionButton={
                    <ButtonCompo onClick={addSection}>Save</ButtonCompo>
                  }
                >
                  <InputFieldsWithIcon
                    label="Keyward Name"
                    onChange={(event) => setSectionName(event?.target?.value)}
                  />
                  <InputFieldsWithIcon
                    label="Description"
                    onChange={(event) => setDesc(event?.target?.value)}
                  />
                </DialogBox>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Keywards</Heading>
          <div className="w-full mt-5">
            <div className="w-full flex justify-end gap-3">
              <ButtonCompo
                variant={"outline"}
                startIcon={<IoMdRefresh />}
                onClick={() => setReload((prev) => !prev)}
              >
                Refresh
              </ButtonCompo>
              <DialogBox
                trigger={
                  <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                    New Keyward
                  </ButtonCompo>
                }
                title="Add New Keyward"
                actionButton={
                  <ButtonCompo onClick={addSection}>Save</ButtonCompo>
                }
              >
                <InputFieldsWithIcon
                  label="Keyward Name"
                  onChange={(event) => setSectionName(event?.target?.value)}
                />
                <InputFieldsWithIcon
                  label="Description"
                  onChange={(event) => setDesc(event?.target?.value)}
                />
              </DialogBox>
            </div>
            <div className="w-full flex items-start gap-5 justify-center mt-5">
              <div className="w-full">
                <Table>
                  <TableHead>
                    <TableRow className="bg-[#FF8000]">
                      <TableCell className="!text-center !text-white !font-semibold">
                        SL No
                      </TableCell>
                      <TableCell className="!text-center !text-white !font-semibold">
                        Name
                      </TableCell>
                      <TableCell className="!text-center !text-white !font-semibold">
                        Enable/Disable
                      </TableCell>
                      <TableCell className="!text-center !text-white !font-semibold">
                        Edit
                      </TableCell>
                      <TableCell className="!text-center !text-white !font-semibold">
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sectionData?.map((section, i) => (
                      <TableRow className="bg-white">
                        <TableCell className="!text-center">{i + 1}</TableCell>
                        <TableCell className="!text-center">
                          {section?.name}
                        </TableCell>
                        <TableCell className="!text-center">
                          {section?.isActive === true ? "Enabled" : "Disabled"}
                        </TableCell>
                        <TableCell className="!text-center">
                          <DialogBox
                            trigger={
                              <ButtonCompo onClick={() => setSecValue(section)}>
                                Edit
                              </ButtonCompo>
                            }
                            title="Add New Section"
                            actionButton={
                              <ButtonCompo
                                onClick={() => editSection(section?._id)}
                              >
                                Save
                              </ButtonCompo>
                            }
                          >
                            <div className="space-y-3">
                              <InputFieldsWithIcon
                                label="Section Name"
                                onChange={(event) =>
                                  setSectionName(event?.target?.value)
                                }
                                value={sectionName}
                              />
                              <InputFieldsWithIcon
                                label="Description"
                                onChange={(event) =>
                                  setDesc(event?.target?.value)
                                }
                                value={desc}
                              />
                              <SelectField
                                label={"Enable/Disable"}
                                setValue={(event) =>
                                  setIsActive(event?.target?.value)
                                }
                                valueArray={isActiveArray}
                                value={isActive}
                              />
                            </div>
                          </DialogBox>
                        </TableCell>
                        <TableCell className="!text-center">
                          <ButtonCompo
                            variant={"destructive"}
                            onClick={() => deleteSection(section?._id)}
                          >
                            Delete
                          </ButtonCompo>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default STKeyward;
