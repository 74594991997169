import { Box, Drawer } from "@mui/material";
import React, { useState } from "react";

function RightDrawer({ actionButton, width="250", children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };
  return (
    <>
      <div onClick={toggleDrawer(true)}>{actionButton}</div>
      <Drawer open={isOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: width }}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <div className="w-full">{children}</div>
        </Box>
      </Drawer>
    </>
  );
}

export default RightDrawer;
