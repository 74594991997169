import React, { useEffect, useState } from "react";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { IoLogoAndroid } from "react-icons/io";
import { axiosInstance } from "../../constant";
import NotFound from "../../component/NotFound";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import { MdRefresh } from "react-icons/md";
import { FaAndroid } from "react-icons/fa6";
import { FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

function SAAppRelease() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [buildNumber, setBuildNumber] = useState(0);
  const [versionName, setVersionName] = useState("");
  const [description, setDescription] = useState("");
  const [releaseData, setReleaseData] = useState([]);

  // add release
  async function addRelease() {
    if (
      buildNumber <= 0 ||
      versionName?.length === 0 ||
      description?.length === 0
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("All fields are required");
      return null;
    }
    if (isNaN(buildNumber) || Number(buildNumber) <= 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Build number must be a number and bigger than 0");
      return null;
    }
    try {
      setLoading(true);
      const data = {
        buildNumber: buildNumber,
        versionName: versionName,
        description: description,
      };
      const res = await axiosInstance.post(`/api/v1/release/add`, data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Release added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Release add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // edit release
  async function editRelease(releaseId) {
    if (
      buildNumber <= 0 ||
      versionName?.length === 0 ||
      description?.length === 0
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("All fields are required");
      return null;
    }
    if (isNaN(buildNumber) || Number(buildNumber) <= 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Build number must be a number and bigger than 0");
      return null;
    }
    try {
      setLoading(true);
      const data = {
        buildNumber: buildNumber,
        versionName: versionName,
        description: description,
        releaseId: releaseId,
      };
      console.log(data);
      const res = await axiosInstance.put(`/api/v1/release/edit`, data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Release updated successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Release update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // delete release
  async function deleteRelease(releaseId) {
    if (releaseId <= 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Release ID is required");
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.delete(`/api/v1/release/delete/${releaseId}`);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Release deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Release delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get releases
  async function getReleases() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/release/get/all`);
      setReleaseData(res.data?.data);
      console.log(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function setData(data) {
    setBuildNumber(data?.buildNumber);
    setVersionName(data?.versionName);
    setDescription(data?.description);
  }

  useEffect(() => {
    getReleases();
  }, [reload]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw] py-10">
          <h1 className="text-[30px] font-semibold text-orange-800 text-center">
            App Releases
          </h1>
          <div className="w-full mt-5">
            <div className="flex justify-end gap-5">
              <ButtonCompo
                variant={"outline"}
                startIcon={<MdRefresh />}
                onClick={() => setReload((prev) => !prev)}
              >
                Refresh
              </ButtonCompo>
              <DialogBox
                trigger={
                  <ButtonCompo
                    variant={"primary"}
                    startIcon={<IoLogoAndroid />}
                  >
                    New Release
                  </ButtonCompo>
                }
                title="New Release"
                actionButton={
                  <ButtonCompo onClick={addRelease}>Save</ButtonCompo>
                }
              >
                <div className="w-full space-y-3">
                  <InputFieldsWithIcon
                    label="Build Number"
                    placeholder="E.g, 2, 3, 4, 5 etc"
                    onChange={(event) => setBuildNumber(event?.target?.value)}
                  />
                  <InputFieldsWithIcon
                    label="Version Name"
                    placeholder={"E.g, 1.2, 1.3 etc"}
                    onChange={(event) => setVersionName(event?.target?.value)}
                  />
                  <InputFieldsWithIcon
                    label="Description"
                    multiline
                    placeholder="E.g, new features, issue fix, security issue fix etc"
                    onChange={(event) => setDescription(event?.target?.value)}
                  />
                </div>
              </DialogBox>
            </div>
            <div className="w-full mt-5">
              <div>
                {releaseData?.length === 0 ? (
                  <div className="">
                    <NotFound />
                  </div>
                ) : (
                  <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
                    {releaseData?.map((release, i) => (
                      <section className="border w-full min-w-[10rem] p-5 rounded-[10px] bg-[#fffeff] shadow-md cursor-pointer hover:shadow-lg">
                        <div className="flex justify-start items-center gap-2">
                          <FaAndroid size={25} className="text-green-500" />
                          <span className="bg-slate-200 px-3 py-[1px] rounded-full text-[14px] text-slate-700">
                            {release?.date}
                          </span>
                          {i === 0 ? (
                            <span className="bg-green-100 text-green-600 px-3 py-[1px] text-[14px] rounded-full font-semibold">
                              Latest
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mt-3 space-y-1">
                          <p className="text-slate-500 text-[15px]">
                            Build Number
                            <br />{" "}
                            <span className="text-slate-800 font-medium text-[16px]">
                              {release?.buildNumber}
                            </span>
                          </p>
                          <p className="text-slate-500 text-[15px]">
                            Version
                            <br />{" "}
                            <span className="text-slate-800 font-medium text-[16px]">
                              {release?.versionName}
                            </span>
                          </p>
                          <section>
                            <p className="text-slate-500 text-[15px]">
                              Description
                            </p>
                            <p className="truncate">{release?.description}</p>
                          </section>
                        </div>
                        <div className="mt-3 flex justify-end gap-5 items-center">
                          <section
                            className="bg-red-100 p-2 rounded-full hover:bg-red-200"
                            onClick={() => deleteRelease(release?._id)}
                          >
                            <MdDelete size={15} className="text-red-600" />
                          </section>
                          <DialogBox
                            trigger={
                              <section
                                className="bg-green-100 p-2 rounded-full hover:bg-green-200"
                                onClick={() => setData(release)}
                              >
                                <FaPen size={15} className="text-green-600" />
                              </section>
                            }
                            title="New Release"
                            actionButton={
                              <ButtonCompo
                                onClick={() => editRelease(release?._id)}
                              >
                                Save
                              </ButtonCompo>
                            }
                          >
                            <div className="w-full space-y-3">
                              <InputFieldsWithIcon
                                label="Build Number"
                                placeholder="E.g, 2, 3, 4, 5 etc"
                                onChange={(event) =>
                                  setBuildNumber(event?.target?.value)
                                }
                                value={buildNumber}
                              />
                              <InputFieldsWithIcon
                                label="Version Name"
                                placeholder={"E.g, 1.2, 1.3 etc"}
                                onChange={(event) =>
                                  setVersionName(event?.target?.value)
                                }
                                value={versionName}
                              />
                              <InputFieldsWithIcon
                                label="Description"
                                multiline
                                placeholder="E.g, new features, issue fix, security issue fix etc"
                                onChange={(event) =>
                                  setDescription(event?.target?.value)
                                }
                                value={description}
                              />
                            </div>
                          </DialogBox>
                        </div>
                      </section>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SAAppRelease;
