import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox, MdCloudUpload } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Box,
  Chip,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import PopOverBox from "../../component/PopoverBox";
import PaginationBar from "../../component/PaginationBar";
import RightDrawer from "../../component/RightDrawer";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";

function STWithdrawalReq() {
  // navigate to home if staff is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [reload, setReload] = useState(false);
  const [files, setFiles] = useState([]);
  const fileRef = useRef(null);

  // get withdrawal req
  async function getProducts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/wallet/get/all/withdrawals`);
      setProductData(res.data?.data);
      console.log(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // approve req
  async function approveReq(transId) {
    console.log("hi");
    if (files.length > 5) {
      setOpen(true);
      setSuccess(false);
      setMessage("Upload 5 files maximum");
      return null;
    }
    console.log("hi");
    let formData = new FormData();
    console.log(files)
    Array.from(files)?.map((file) => {
      if (file) {
        formData.append("supportingDocs", file);
      }
    });
    formData.append("withdrawalId", transId);
    console.log("hi");
    try {
      setLoading(true);
      const res = await axiosInstance.post(
        `/api/v1/wallet/withdrawal/create`,
        formData
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Transanction added successfully");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Coin add failed");
    } finally {
      setLoading(false);
    }
  }

  function onReload() {
    setReload((prev) => !prev);
  }

  useEffect(() => {
    getProducts();
  }, [reload]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Withdrawal Requests</Heading>
          <div className="w-full mt-5">
            {productData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full flex justify-end gap-3 mt-[2rem]">
                  <ButtonCompo
                    variant={"outline"}
                    startIcon={<IoMdRefresh />}
                    onClick={onReload}
                  >
                    Refresh
                  </ButtonCompo>
                </div>
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          User
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Phone Number
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Amount
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          UPI ID
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Bank Account Number
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          IFSC Code
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Status
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Approve
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {productData?.map((trans, i) => (
                        <TableRow>
                          <TableCell className="!text-center">
                            {i + 1}
                          </TableCell>
                          <TableCell className="!text-center !font-semibold">
                            {trans?.user?.fullName}
                          </TableCell>
                          <TableCell className="!text-center">
                            {trans?.user?.phone}
                          </TableCell>
                          <TableCell className="!text-center !space-x-2">
                            {trans?.amount}
                          </TableCell>
                          <TableCell className="!text-center">
                            {trans?.upiId ? trans?.upiId : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {trans?.bankAcNumber ? trans?.bankAcNumber : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {trans?.ifscCode ? trans?.ifscCode : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {trans?.isDone ? "Completed" : "Incomplete"}
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox
                              trigger={<ButtonCompo>Approve</ButtonCompo>}
                              title="Create Withdrawal Transanction"
                              actionButton={
                                <ButtonCompo
                                  onClick={() => approveReq(trans?._id)}
                                >
                                  Approve
                                </ButtonCompo>
                              }
                            >
                              <input
                                type="file"
                                className="hidden"
                                onChange={(event) =>
                                  setFiles(event?.target?.files)
                                }
                                ref={fileRef}
                                multiple
                              />
                              <p className="mb-3 text-slate-500">
                                Upload supporting documents(e.g, Screenshots,
                                bank recipt etc.)
                              </p>
                              <section
                                onClick={() => fileRef?.current?.click()}
                                className="flex w-full justify-between items-center border-slate-300 border rounded-[5px] py-4 px-3 cursor-pointer hover:border-[#FF8000]"
                              >
                                <p className="text-slate-700 font-medium">
                                  {files?.length === 0
                                    ? "Upload Documents"
                                    : `${files.length} file selcted`}
                                </p>
                                <MdCloudUpload
                                  size={"25px"}
                                  className="text-[#FF8000]"
                                />
                              </section>
                            </DialogBox>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* ; */
}

export default STWithdrawalReq;
