import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function PaginationBar({tablePage, totalTablePage, setTablePage }) {
  return (
    <div className="w-full flex justify-end p-3 bg-white">
      <section className="flex items-center justify-center gap-3">
        <p className="text-[15px]">
          {tablePage} of {totalTablePage} Pages
        </p>
        <div className="flex items-center justify-center">
          <button
            disabled={tablePage === 1 ? true : false}
            className="px-2"
            onClick={() => setTablePage((prev) => prev - 1)}
          >
            <IoIosArrowBack
              className={`${
                tablePage === 1 ? "text-slate-300" : "text-slate-500"
              } text-[20px]`}
            />
          </button>
          <button
            disabled={tablePage === totalTablePage ? true : false}
            className="px-2"
            onClick={() => setTablePage((prev) => prev + 1)}
          >
            <IoIosArrowForward
              className={`${
                tablePage === totalTablePage
                  ? "text-slate-300"
                  : "text-slate-500"
              } text-[20px]`}
            />
          </button>
        </div>
      </section>
    </div>
  );
}

export default PaginationBar;